import React from "react";
import { Grommet } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateLayout, PublicLayout } from "./layouts";

const App = ({ authenticated, checked }) => {
	return (
		<Grommet theme={hpe} full>
			<Router>
				<Switch>
					<Route path='/embed'><PublicLayout /> </Route>
					<Route path='/'><PrivateLayout authenticated={authenticated} checked={checked} /> </Route>
				</Switch>
			</Router>
		</Grommet >
	);
};

const { bool } = PropTypes;

App.propTypes = {
	authenticated: bool.isRequired,
	checked: bool.isRequired,
};

const mapState = ({ session }) => ({
	checked: session.checked,
	authenticated: session.authenticated,
});

export default connect(mapState)(App);
