import React, { useEffect } from "react";
import { Box, Text, Heading, Video, Spinner, Layer } from "grommet";
import { useSelector, useDispatch } from "react-redux";
import { dashboardActions } from "../redux/actions";

export const HelpPage = () => {
	const dispatch = useDispatch();

	const session = useSelector((state) => {
		return state.session;
	});

	useEffect(() => {
		if (session && session.user && session.user.access) {
			dispatch(dashboardActions.getDashboardHeader(session.user.access));
			dispatch(dashboardActions.getDashboardVideo(session.user.access));
		}
	}, [session, dispatch]);

	const dashboardHeader = useSelector((state) => {
		return state.dashboard.dashboardHeader;
	});

	const dashboardVideo = useSelector((state) => {
		return state.dashboard.dashboardVideo;
	});

	return (
		<Box>
			{(dashboardHeader.requestInProgress || dashboardVideo.requestInProgress) && (
				<Layer position='center' background='none' style={{ boxShadow: "none" }}>
					<Spinner />
				</Layer>
			)}
			<Box justify='center' pad='small'>
				<Heading color='black' margin='none' level={4} pad='xxlarge'>
					Site Guide
				</Heading>

				<Text color='black' size='small' pad='medium'>
					{dashboardHeader.data && dashboardHeader.data.dashboard_header_content_line2}
				</Text>
			</Box>

			{dashboardVideo.data && (
				<Video
					id='help_video'
					aria-label={`help-video`}
					poster='/static/images/hpe_storage.jpg'
					height='large'
					margin='medium'
					fit='cover'
					alignSelf='center'
					autoPlay
				>
					<source src={dashboardVideo.data.upload_video} type='video/mp4' />
					<track key='cc' label='English' kind='subtitles' srcLang='en' src='/assets/small-en.vtt' default />
				</Video>
			)}
		</Box>
	);
};

export default HelpPage;
