import { ActionTypes } from "../constants/actionTypes";

const initialState = {
    competitorProducts: { data: null, requestInProgress: false, error: null },
    singelClickResource: { data: null, requestInProgress: false, error: null },
    resourceTypes: { data: null, requestInProgress: false, error: null },
    resources: { data: null, requestInProgress: false, error: null }
};

export function competetiveProducts(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.COMPETITOR_PRODUCTS_REQUEST:
            return { ...state, competitorProducts: { requestInProgress: true } };
        case ActionTypes.COMPETITOR_PRODUCTS_SUCCESS:
            return { ...state, competitorProducts: { data: action.competitorProducts, requestInProgress: false } };
        case ActionTypes.COMPETITOR_PRODUCTS_FAILURE:
            return { ...state, competitorProducts: { requestInProgress: false, error: action.error } };
        case ActionTypes.RESOURCE_TYPES_REQUEST:
            return { ...state, resourceTypes: { requestInProgress: true } };
        case ActionTypes.RESOURCE_TYPES_SUCCESS:
            return { ...state, resourceTypes: { data: action.resourceTypes, requestInProgress: false } };
        case ActionTypes.RESOURCE_TYPES_FAILURE:
            return { ...state, resourceTypes: { requestInProgress: false, error: action.error } }
        case ActionTypes.RESOURCES_REQUEST:
            return { ...state, resources: { requestInProgress: true } };
        case ActionTypes.RESOURCES_SUCCESS:
            return { ...state, resources: { data: action.resources, requestInProgress: false } };
        case ActionTypes.RESOURCES_FAILURE:
            return { ...state, resources: { requestInProgress: false, error: action.error } };
        case ActionTypes.SINGLE_CLICK_REQUEST:
            return { ...state, singelClickResource: { requestInProgress: true } };
        case ActionTypes.SINGLE_CLICK_SUCCESS:
            return { ...state, singelClickResource: { data: action.singleclickResources, requestInProgress: false } };
        case ActionTypes.SINGLE_CLICK_FAILURE:
            return { ...state, singelClickResource: { requestInProgress: false, error: action.error } };
        default:
            return state;
    }
}
