import React, { useEffect } from "react";
import { Box, Layer, Main, Spinner } from "grommet";
import { useHistory } from "react-router-dom";
import { userActions } from "../redux/actions/userActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const LoginPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const search = useLocation().search;
	const code = new URLSearchParams(search).get("code");

	useEffect(() => {
		console.log({ code })
		dispatch(userActions.login(code, history));
		// eslint-disable-next-line
	}, [code]);
	console.log("Login Page")
	return (
		<Main align='center'>
			<Box direction='row' pad='xlarge'>
				<Layer position='center' background='none' style={{ boxShadow: "none" }}>
					<Spinner />
				</Layer>
			</Box>
		</Main>
	);
};

export default LoginPage;
