import { ActionTypes } from "../constants/actionTypes";

const initialState = {};

export function survey(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SURVEY_SUBMIT:
			return { ...state, user: {} };
		case ActionTypes.SURVEY_SUCCESS:
			return { ...state, user: {} };
		case ActionTypes.SURVEY_FAILURE:
			return {};
		default:
			return state;
	}
}
