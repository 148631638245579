import React from "react";
import { Grid } from "grommet";
import { useSelector } from "react-redux";
import { CustomCard } from "./CustomCard";

export const CompetitiveSinlgeClickGrid = ({ productsList, singelClickId, history, size, ...rest }) => {
    const products = useSelector(() => {
        productsList.sort((a, b) => (a.order > b.order) ? 1 : -1);
        return productsList.map((group, index) => {
            const productsDet = {
                id: group.id,
                name: group.single_click_resource_title,
                description: group.single_click_resource_description,
                page_link: group.single_click_link,
            };
            return (
                <CustomCard
                    key={productsDet.id}
                    title={productsDet.name}
                    description={productsDet.description}
                    onClickHandler={() => {
                        window.open(productsDet.page_link)
                    }}
                />
            );
        });
    });

    return (
        <Grid
            columns={"20%"} gap={"medium"}
            rows={[["auto", "full"]]}
            {...rest}
            pad='medium'
            fill
        >
            {products}
        </Grid>
    );
};