import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import { alert } from "./alertReducer";
import { dashboard } from "./dashboardReducer";
import { productGroup } from "./productGroupReducer";
import { demo } from "./demoReducer";
import { feedback } from "./feedbackReducer";
import { survey } from "./surveyReducer";
import { competitiveDash } from "./competitiveDashReducer"
import { competetiveProducts } from "./competeteiveProductsReducer";

const reducers = combineReducers({
	alert: alert,
	session: sessionReducer,
	dashboard: dashboard,
	productGroup: productGroup,
	demo: demo,
	feedback: feedback,
	survey: survey,
	competitiveDash: competitiveDash,
	competitiveProducts: competetiveProducts
});

export default reducers;
