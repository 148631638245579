import React, { useEffect, useContext } from "react";
import { Box, Layer, ResponsiveContext, Spinner } from "grommet";
import { DashboardGrid, DashboardHead } from "../components/dashboard";
import { useSelector, useDispatch } from "react-redux";
import { dashboardActions, productGroupActions } from "../redux/actions";
import { useHistory } from "react-router-dom";

export const Dashboard = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const size = useContext(ResponsiveContext);

	const session = useSelector((state) => {
		return state.session;
	});

	const user = useSelector((state) => {
		return state.session.user;
	});

	useEffect(() => {
		if (session && session.user && session.user.access) {
			dispatch(dashboardActions.getDashboardHeader(session.user.access));
			dispatch(productGroupActions.getProductGroupList(session.user.access));
		}
	}, [session, dispatch]);

	const dashboardHeader = useSelector((state) => {
		return state.dashboard.dashboardHeader;
	});

	const productGroupList = useSelector((state) => {
		return state.dashboard.productGroupList;
	});

	return (
		<>
			{
				<Box gap='medium'>
					{(dashboardHeader.requestInProgress || productGroupList.requestInProgress) && (
						<Layer position='center' background='none' style={{ boxShadow: "none" }}>
							<Spinner />
						</Layer>
					)}
					{dashboardHeader.data && (
						<DashboardHead
							user={user}
							dashboardHeader={dashboardHeader.data}
							history={history}
							size={size}
						/>
					)}
					{productGroupList.data && (
						<DashboardGrid
							dashboardProductGroupList={productGroupList.data}
							history={history}
							size={size}
						/>
					)}
				</Box>
			}
		</>
	);
};

export default Dashboard;
