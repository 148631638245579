import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useHistory } from "react-router";
import { Anchor, Tip, Text, Box } from "grommet";

export const DemoCrumbs = ({ demo, product_group_id, ...rest }) => {
	const history = useHistory();
	const backClick = () => {
		history.push("/product-group/" + product_group_id);
	};

	return (
		<Box gap='medium' pad='small'>
			<Breadcrumbs aria-label='breadcrumb' background='secondary'>
				<Tip
					content={
						<Box width={{ max: "small" }} round='xsmall'>
							<Text>
								Back to{" "}
								{
									demo.demo.product_group.filter(
										(product_group) => (product_group.id = product_group_id)
									)[0].product_group_name
								}
							</Text>
						</Box>
					}
				>
					<Anchor
						onClick={backClick}
						label={
							demo.demo.product_group.filter((product_group) => (product_group.id = product_group_id))[0]
								.product_group_name
						}
						size='xsmall'
					/>
				</Tip>
				<Text size='xsmall'>{demo.demo.demo_title}</Text>
			</Breadcrumbs>
		</Box>
	);
};
