import React from "react";
import { Box, Button } from "grommet";
import { NotificationLayer } from "..";

export const LiveDemoContainer = ({ size, demo, setSurveyPopup }) => {
	var lab;
	var labGuide;

	const popfocus = () => {
		if (lab && !lab.closed) {
			//checks to see if window is open
			lab.focus();
		}
		if (labGuide && !labGuide.closed) {
			//checks to see if window is open
			labGuide.focus();
		}
	};

	const end_session = (sub_session_id) => {
		lab && lab.close();
		labGuide && labGuide.close();
		setSurveyPopup(true);
	};

	const openLab = () => {
		if (lab && !lab.closed) {
			// checks to see if window is open
			popfocus();
		} else {
			lab = window.open(
				demo.demo_link,
				"Lab",
				"width=" +
					window.innerWidth * 0.65 +
					", height=" +
					window.innerHeight +
					", resizeable=0, top=0, left=" +
					window.innerWidth * 0.4
			);
			if (!lab || lab.closed || typeof lab.closed == "undefined") {
				//POPUP BLOCKED
				onOpenNotification();
			}
			popfocus();
			var pollTimer = window.setInterval(function () {
				if (lab.closed !== false) {
					window.clearInterval(pollTimer);
					end_session(9);
				}
			}, 200);
		}
	};

	const openLabGuide = () => {
		if (labGuide && !labGuide.closed) {
			//checks to see if window is open
			popfocus();
		} else {
			labGuide = window.open(
				demo.document_link || demo.upload_document,
				"Lab Guide",
				"width=" + window.innerWidth * 0.3 + ", height=" + window.innerHeight + ", resizeable=0, top=0, left=0"
			);
			if (!labGuide || labGuide.closed || typeof labGuide.closed == "undefined") {
				//POPUP BLOCKED
				onOpenNotification();
			}
			popfocus();
		}
	};

	const startDemo = () => {
		openLab();
		openLabGuide();
	};

	const [openNotification, setOpenNotification] = React.useState();

	const onOpenNotification = () => {
		setOpenNotification(true);
		setTimeout(() => {
			setOpenNotification(undefined);
		}, 3000);
	};

	const onCloseNotification = () => setOpenNotification(undefined);

	return (
		<>
			{
				<Box
					pad='medium'
					height={size !== "small" ? "large" : "medium"}
					width='100%'
					gap='medium'
					margin='auto'
					align='stretch'
				>
					{openNotification && (
						<NotificationLayer
							notificationMessage={"POPUP BLOCKED"}
							closeNotification={onCloseNotification}
							info={true}
						/>
					)}
					<Button
						a11yTitle='OPEN LAB GUIDE'
						title='OPEN LAB GUIDE'
						label='OPEN LAB GUIDE'
						onClick={openLabGuide}
						primary
						size='large'
					></Button>
					<Button
						a11yTitle='OPEN LAB'
						title='OPEN LAB'
						label='OPEN LAB'
						onClick={openLab}
						primary
						size='large'
					></Button>
					<Button
						a11yTitle='START YOUR LIVE DEMO'
						title='START YOUR LIVE DEMO'
						label='START YOUR LIVE DEMO'
						onClick={startDemo}
						primary
						size='large'
					></Button>
				</Box>
			}
		</>
	);
};

export default LiveDemoContainer;
