import { analyticsService, demoService } from "../../services";
import { ActionTypes } from "../constants/actionTypes";
import { LogEvents } from "../constants/logEvents";
import { alertError } from "./alertActions";

export const demoActions = {
	getProductDemoList,
	getDemo,
};

function getProductDemoList(id, access) {
	return (dispatch) => {
		dispatch(request({}));
		demoService
			.getProductDemoList(id, access)
			.then((productDemoList) => {
				dispatch(success(productDemoList));
				analyticsService.logEvent(access, LogEvents.DEMO_LIST, id);
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.PRODUCT_GROUP_DEMO_LIST_REQUEST, user };
	}
	function success(productDemoList) {
		return { type: ActionTypes.PRODUCT_GROUP_DEMO_LIST_SUCCESS, productDemoList };
	}
	function failure(error) {
		return { type: ActionTypes.PRODUCT_GROUP_DEMO_LIST_FAILURE, error };
	}
}

function getDemo(id, product_group_id, access) {
	return (dispatch) => {
		dispatch(request({}));
		demoService
			.getDemo(id, access)
			.then((demo) => {
				dispatch(success(demo));
				analyticsService.logEvent(access, LogEvents.DEMO, product_group_id, id);
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.DEMO_REQUEST, user };
	}
	function success(demo) {
		return { type: ActionTypes.DEMO_SUCCESS, demo };
	}
	function failure(error) {
		return { type: ActionTypes.DEMO_FAILURE, error };
	}
}
