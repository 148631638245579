import React, { useEffect, useContext, useState } from "react";
import { ProductGroupHead, DemoListMain } from "../components/product-group";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { productGroupActions, demoActions } from "../redux/actions";
import { Box, Layer, ResponsiveContext, Spinner, TextInput } from "grommet";
import { useSelector, useDispatch } from "react-redux";
import { Search as SearchIcon } from "grommet-icons";
import styled from "styled-components";
import { SurveyCard } from "../components/survey";
import { NotificationLayer } from "../components/global-header";

const StyledTextInput = styled(TextInput).attrs(() => ({
	"aria-labelledby": "search-icon",
}))``;

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export const ProductGroup = () => {
	const dispatch = useDispatch();
	const size = useContext(ResponsiveContext);
	const { id } = useParams();
	const searchQuery = useQuery().get("searchQuery");
	const history = useHistory();

	let hash = history.location.hash.replace("#", "");
	const [index, setIndex] = React.useState(hash ? parseInt(hash) : 0);

	const [search, setSearch] = React.useState(searchQuery ? searchQuery : "");

	const session = useSelector((state) => {
		return state.session;
	});

	const [demoList, setDemoList] = React.useState();

	const [demo, setDemo] = useState();

	const [surveyPopup, setSurveyPopup] = useState(false);
	const [openSurveyNotification, setOpenSurveyNotification] = React.useState();

	const onOpenSurveyNotification = () => {
		setOpenSurveyNotification(true);
		setTimeout(() => {
			setOpenSurveyNotification(undefined);
		}, 3000);
	};

	const onCloseSurveyNotification = () => setOpenSurveyNotification(undefined);

	useEffect(() => {
		if (session && session.user && session.user.access) {
			dispatch(productGroupActions.getProductGroup(id, session.user.access));
			dispatch(productGroupActions.getContentTypes(session.user.access));
			dispatch(demoActions.getProductDemoList(id, session.user.access));
		}
	}, [session, id, dispatch]);

	const productGroup = useSelector((state) => {
		return state.productGroup && state.productGroup.productGroup;
	});

	const contentTypes = useSelector((state) => {
		return state.productGroup && state.productGroup.contentTypes;
	});

	const initialDemo = useSelector((state) => {
		return state.demo && state.demo.demoList;
	});

	useEffect(() => {
		if (initialDemo.data) {
			setDemoList(initialDemo.data);

			search ? (history.location.search = "?searchQuery=" + search) : (history.location.search = "");
			history.push(history.location.pathname + history.location.search + history.location.hash);
			let newDemoList = initialDemo.data.filter(
				(demo) =>
					demo.demo_title.toLowerCase().indexOf(search) !== -1 ||
					demo.demo_header_info.toLowerCase().indexOf(search) !== -1
			);
			setDemoList(newDemoList);
			setIndex(0);
		}
		// eslint-disable-next-line
	}, [initialDemo, search]);

	return (
		<>
			{
				<Box gap='medium'>
					{surveyPopup && (
						<SurveyCard
							setSurveyPopup={setSurveyPopup}
							openSurveyPopup={surveyPopup}
							demo={demo.id}
							demoName={demo.name}
							product_group_id={id}
							setNotification={onOpenSurveyNotification}
						/>
					)}
					{openSurveyNotification && (
						<NotificationLayer
							notificationMessage={"Review submitted successfully!"}
							closeNotification={onCloseSurveyNotification}
						/>
					)}

					{(productGroup.requestInProgress ||
						contentTypes.requestInProgress ||
						initialDemo.requestInProgress) && (
						<Layer position='center' background='none' style={{ boxShadow: "none" }}>
							<Spinner />
						</Layer>
					)}
					{productGroup.data && contentTypes.data && demoList && (
						<ProductGroupHead
							productGroup={productGroup.data}
							height={size !== "small" ? "small" : "auto"}
						/>
					)}
					{productGroup.data && contentTypes.data && demoList && (
						<Box width={{ max: "large" }} pad={{ horizontal: "medium" }}>
							<StyledTextInput
								icon={<SearchIcon id='search-icon' />}
								placeholder='Search'
								reverse
								value={search}
								onChange={(event) => setSearch(event.target.value)}
							/>
						</Box>
					)}
					{productGroup.data && contentTypes.data && demoList && (
						<DemoListMain
							contentTypes={contentTypes.data}
							demoList={demoList}
							history={history}
							product_group_id={id}
							access={session.user.access}
							setSurveyPopup={setSurveyPopup}
							setDemo={setDemo}
							index={index}
							setIndex={setIndex}
						/>
					)}
				</Box>
			}
		</>
	);
};

export default ProductGroup;
