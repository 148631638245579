import { ActionTypes } from "../constants/actionTypes";

const initialState = {};

export function feedback(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.FEEDBACK_SUBMIT:
			return { ...state, user: {} };
		case ActionTypes.FEEDBACK_SUCCESS:
			return { ...state, user: {} };
		case ActionTypes.FEEDBACK_FAILURE:
			return {};
		default:
			return state;
	}
}
