import React from "react";
import { Box } from "grommet";

export const DemoEmbedContainer = ({ size, demo }) => (
	<>
		{
			<Box pad='medium' height={size !== "small" ? "large" : "medium"} width='100%'>
				<iframe
					title={demo.title || demo.demo_title}
					src={demo.link || demo.demo_link}
					style={{
						height: "100%",
						width: "100%",
					}}
					frameBorder='0'
					allowFullScreen
				></iframe>
			</Box>
		}
	</>
);

export default DemoEmbedContainer;
