import React, { useEffect } from "react";
import { Box, Grid, Button, Layer, Spinner } from "grommet";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { dashboardActions } from "../redux/actions/dashboardActions";
import { useSelector, useDispatch } from "react-redux";

import {
	Scheduler,
	DayView,
	WeekView,
	MonthView,
	Appointments,
	Toolbar,
	ViewSwitcher,
	DateNavigator,
	TodayButton,
	AppointmentTooltip,
	AllDayPanel,
	Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { analyticsService } from "../services";
import { LogEvents } from "../redux/constants/logEvents";

export const StorageCalendar = () => {
	const dispatch = useDispatch();
	const session = useSelector((state) => {
		return state.session;
	});

	const resources = [
		{
			fieldName: "type",
			title: "Type",
			instances: [
				{
					text: "Event",
					id: 1,
					color: "#00739D",
				},
			],
		},
	];

	const mapAppointmentData = (appointment) => ({
		startDate: appointment.start_date,
		endDate: appointment.end_date,
		title: appointment.event_name,
		link: appointment.link,
		description: appointment.description,
		id: appointment.id,
		type: 1,
	});

	useEffect(() => {
		if (session && session.user && session.user.access) {
			dispatch(dashboardActions.getCalendar(session.user.access));
		}
	}, [session, dispatch]);

	const calendar = useSelector((state) => {
		return state.dashboard.calendar;
	});

	const [visible, setVisible] = React.useState();

	const [appointmentMeta, setAppointmentMeta] = React.useState({
		target: null,
		data: {},
	});

	const onAppointmentMetaChange = ({ data, target }) => {
		setAppointmentMeta({ data, target });
	};

	const tooltipVisibility = () => {
		setVisible(!visible);
	};

	const myAppointment = ({ children, style, data, onClick, ...restProps }) => (
		<Appointments.Appointment
			{...restProps}
			onClick={({ target }) => {
				tooltipVisibility();
				onAppointmentMetaChange({ target: target.parentElement.parentElement, data });
			}}
		>
			{children}
		</Appointments.Appointment>
	);

	const myAppointmentTooltip = ({ children, style, appointmentData, classes, ...restProps }) => (
		<AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
			{appointmentData.link && (
				<Grid alignItems='center'>
					<Grid>
						<Button
							onClick={() => {
								window.open(appointmentData.link, "_blank");
								analyticsService.logEvent(
									session.user.access,
									LogEvents.CALENDAR_EVENT,
									null,
									null,
									appointmentData.id
								);
							}}
							label='Open'
						/>
					</Grid>
				</Grid>
			)}
		</AppointmentTooltip.Content>
	);

	return (
		<Box height='large'>
			{calendar.requestInProgress && (
				<Layer position='center' background='none' style={{ boxShadow: "none" }}>
					<Spinner />
				</Layer>
			)}
			<Scheduler data={calendar.data ? calendar.data.map(mapAppointmentData) : []}>
				<ViewState defaultCurrentViewName='Month' />
				<DayView startDayHour={0} endDayHour={24} />
				<WeekView startDayHour={0} endDayHour={24} />
				<MonthView />
				<Toolbar />
				<DateNavigator />
				<TodayButton />
				<AllDayPanel />
				<ViewSwitcher />
				<Appointments appointmentComponent={myAppointment} />
				<AppointmentTooltip
					showCloseButton
					visible={visible}
					onVisibilityChange={tooltipVisibility}
					appointmentMeta={appointmentMeta}
					contentComponent={myAppointmentTooltip}
					onAppointmentMetaChange={onAppointmentMetaChange}
				/>
				<Resources data={resources} />
			</Scheduler>
		</Box>
	);
};

export default StorageCalendar;
