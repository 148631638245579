export const ActionTypes = {
	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILURE: "LOGIN_FAILURE",
	LOGOUT: "LOGOUT",

	ALERT_SUCCESS: "ALERT_SUCCESS",
	ALERT_ERROR: "ALERT_ERROR",
	ALERT_CLEAR: "ALERT_CLEAR",

	DASHBOARD_HEADER_REQUEST: "DASHBOARD_HEADER_REQUEST",
	DASHBOARD_HEADER_SUCCESS: "DASHBOARD_HEADER_SUCCESS",
	DASHBOARD_HEADER_FAILURE: "DASHBOARD_HEADER_FAILURE",

	DASHBOARD_VIDEO_REQUEST: "DASHBOARD_VIDEO_REQUEST",
	DASHBOARD_VIDEO_SUCCESS: "DASHBOARD_VIDEO_SUCCESS",
	DASHBOARD_VIDEO_FAILURE: "DASHBOARD_VIDEO_FAILURE",

	PRODUCT_GROUP_LIST_REQUEST: "PRODUCT_GROUP_LIST_REQUEST",
	PRODUCT_GROUP_LIST_SUCCESS: "PRODUCT_GROUP_LIST_SUCCESS",
	PRODUCT_GROUP_LIST_FAILURE: "PRODUCT_GROUP_LIST_FAILURE",

	PRODUCT_GROUP_REQUEST: "PRODUCT_GROUP_REQUEST",
	PRODUCT_GROUP_SUCCESS: "PRODUCT_GROUP_SUCCESS",
	PRODUCT_GROUP_FAILURE: "PRODUCT_GROUP_FAILURE",

	PRODUCT_GROUP_DEMO_LIST_REQUEST: "PRODUCT_GROUP_DEMO_LIST_REQUEST",
	PRODUCT_GROUP_DEMO_LIST_SUCCESS: "PRODUCT_GROUP_DEMO_LIST_SUCCESS",
	PRODUCT_GROUP_DEMO_LIST_FAILURE: "PRODUCT_GROUP_DEMO_LIST_FAILURE",

	DEMO_REQUEST: "DEMO_REQUEST",
	DEMO_SUCCESS: "DEMO_SUCCESS",
	DEMO_FAILURE: "DEMO_FAILURE",

	CONTENT_TYPES_REQUEST: "CONTENT_TYPES_REQUEST",
	CONTENT_TYPES_SUCCESS: "CONTENT_TYPES_SUCCESS",
	CONTENT_TYPES_FAILURE: "CONTENT_TYPES_FAILURE",

	CALENDAR_REQUEST: "CALENDAR_REQUEST",
	CALENDAR_SUCCESS: "CALENDAR_SUCCESS",
	CALENDAR_FAILURE: "CALENDAR_FAILURE",

	FEEDBACK_SUCCESS: "FEEDBACK_SUCCESS",
	FEEDBACK_FAILURE: "FEEDBACK_FAILURE",
	FEEDBACK_SUBMIT: "FEEDBACK_SUBMIT",

	SURVEY_SUCCESS: "SURVEY_SUCCESS",
	SURVEY_FAILURE: "SURVEY_FAILURE",
	SURVEY_SUBMIT: "SURVEY_SUBMIT",

	METRIC_SUCCESS: "METRIC_SUCCESS",
	METRIC_FAILURE: "METRIC_FAILURE",
	METRIC_SUBMIT: "METRIC_SUBMIT",

	COMPETITOR_REQUEST: "COMPETITOR_REQUEST",
	COMPETITOR_SUCCESS: "COMPETITOR_SUCCESS",
	COMPETITOR_FAILURE: "COMPETITOR_FAILURE",

	SINGLE_CLICK_REQUEST: "SINGLE_CLICK_REQUEST",
	SINGLE_CLICK_SUCCESS: "SINGLE_CLICK_SUCCESS",
	SINGLE_CLICK_FAILURE: "SINGLE_CLICK_FAILURE",

	SINGLE_CLICK_TYPE_REQUEST: "SINGLE_CLICK_TYPE_REQUEST",
	SINGLE_CLICK_TYPE_SUCCESS: "SINGLE_CLICK_TYPE_SUCCESS",
	SINGLE_CLICK_TYPE_FAILURE: "SINGLE_CLICK_TYPE_FAILURE",

	COMPETITOR_PRODUCTS_REQUEST: "COMPETITOR_PRODUCTS_REQUEST",
	COMPETITOR_PRODUCTS_SUCCESS: "COMPETITOR_PRODUCTS_SUCCESS",
	COMPETITOR_PRODUCTS_FAILURE: "COMPETITOR_PRODUCT_FAILURE",

	RESOURCE_TYPES_REQUEST: "RESOURCE_TYPES_REQUEST",
	RESOURCE_TYPES_SUCCESS: "RESOURCE_TYPES_SUCCESS",
	RESOURCE_TYPES_FAILURE: "RESOURCE_TYPES_FAILURE",

	RESOURCES_REQUEST: "RESOURCE_REQUEST",
	RESOURCES_SUCCESS: "RESOURCE_SUCCESS",
	RESOURCES_FAILURE: "RESOURCE_FAILURE"


};
