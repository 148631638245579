import React from "react";
import { Grid } from "grommet";
import { useSelector } from "react-redux";
import { CustomCard } from "../competetive/CustomCard";

export const DashboardGrid = ({ dashboardProductGroupList, history, size, ...rest }) => {
	const productGroupList = useSelector(() => {
		dashboardProductGroupList.sort((a, b) => (a.order > b.order) ? 1 : -1);
		return dashboardProductGroupList.map((group, index) => {
			const groupDet = {
				id: group.id,
				image: group.product_group_thumbnail,
				name: group.product_group_name,
				description: group.product_group_description,
				count: String(group.demo_count),
				page_link: `/product-group/${group.id}`,
			};
			return (
				<CustomCard
					key={group.id}
					card={groupDet}
					onClick={() => {
						history.push(groupDet.page_link);
					}}
				/>
			);
		});
	});

	return (
		<Grid
			columns={size !== "small" ? "medium" : "100%"}
			rows={[["auto", "full"]]}
			gap='medium'
			{...rest}
			pad='medium'
			fill
		>
			{productGroupList}
		</Grid>
	);
};

export default DashboardGrid;
