import { ActionTypes } from "../constants/actionTypes";

const initialState = {
	demoList: { data: null, requestInProgress: false, error: null },
	demo: { data: null, requestInProgress: false, error: null },
};

export function demo(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.PRODUCT_GROUP_DEMO_LIST_REQUEST:
			return { ...state, demoList: { requestInProgress: true } };
		case ActionTypes.PRODUCT_GROUP_DEMO_LIST_SUCCESS:
			return { ...state, demoList: { data: action.productDemoList, requestInProgress: false } };
		case ActionTypes.PRODUCT_GROUP_DEMO_LIST_FAILURE:
			return { ...state, demoList: { requestInProgress: false, error: action.error } };
		case ActionTypes.DEMO_REQUEST:
			return { ...state, demo: { requestInProgress: true } };
		case ActionTypes.DEMO_SUCCESS:
			return { ...state, demo: { data: action.demo, requestInProgress: false } };
		case ActionTypes.DEMO_FAILURE:
			return { ...state, demo: { requestInProgress: false, error: action.error } };
		default:
			return state;
	}
}
