import React from "react";
import { Box, Accordion, AccordionPanel } from "grommet";

export const DemoSidePanel = ({ size, demo }) => (
	<>
		{
			<Box border={{ color: "brand", size: "medium" }} round='small' width={size !== "small" ? "medium" : "fill"}>
				<Accordion width='fill' pad='medium'>
					<AccordionPanel label={demo.title || demo.live_demo_title}>
						<Box pad='small'>{demo.description || demo.live_demo_description}</Box>
					</AccordionPanel>
				</Accordion>
			</Box>
		}
	</>
);

export default DemoSidePanel;
