import React, { useState } from "react";
import PropTypes from "prop-types";
import { Rating } from "@material-ui/lab";
import Chip from "@material-ui/core/Chip";
import { CardBody, Text, CardFooter, Card, Image, Box } from "grommet";
import styled from "styled-components";
import { useHistory } from "react-router";
import { analyticsService } from "../../services";
import { LogEvents } from "../../redux/constants/logEvents";

const StyledCard = styled(Card)`
	transition: all 0.3s ease-in-out;
	border-style: solid;
	border-width: 1px;
	border-color: #efefef;
`;

export const DemoCard = ({ card, setSurveyPopup, setDemo, product_group_id, access, ...rest }) => {
	const { id, name, key, description, is_featured, page_link, type, image, demo_link, is_embedded, survey_rating } =
		card;
	const [isFocused, setIsFocused] = useState(false);
	const MAX_DESC_LENGTH = 150;

	const history = useHistory();
	return (
		<StyledCard
			key={id}
			background='background-front'
			elevation={isFocused ? "medium" : "small"}
			onClick={() => {
				if (demo_link && is_embedded === false) {
					window.open(demo_link);
					setDemo({ id: id, name: name });
					analyticsService.logEvent(access, LogEvents.DEMO, product_group_id, id);
					setSurveyPopup(true);
				} else {
					history.push(page_link);
				}
			}}
			onBlur={() => setIsFocused(false)}
			onFocus={() => setIsFocused(true)}
			onMouseOut={() => setIsFocused(false)}
			onMouseOver={() => setIsFocused(true)}
			width='fill'
		>
			<CardBody gap='xsmall' align='start' flex='grow'>
				<Box height='xsmall' width='small'>
					<Image fit='contain' src={image} />
				</Box>
				<Text size='large' weight='bold' color='text-strong'>
					{name}
				</Text>
				{description.length > MAX_DESC_LENGTH ? (
					<Text numberOfLines={1} ellipsizeMode='end'>
						{" "}
						{`${description.substring(0, MAX_DESC_LENGTH)}...`}{" "}
					</Text>
				) : (
					<Text numberOfLines={1} ellipsizeMode='end'>
						{" "}
						{description}{" "}
					</Text>
				)}
			</CardBody>
			<CardFooter justify='between' background='background-back'>
				<Rating name='read-only' value={survey_rating} readOnly />
				{key === "Featured" ? (
					<Chip label={type} variant='outlined' />
				) : is_featured === true ? (
					<Chip label='Featured' style={{ backgroundColor: "#01a982", color: "#FFF" }} />
				) : (
					<></>
				)}
			</CardFooter>
		</StyledCard>
	);
};

DemoCard.propTypes = {
	card: PropTypes.shape({
		cta: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		title: PropTypes.string,
		description: PropTypes.string,
		active_items: PropTypes.string,
		page_link: PropTypes.string,
	}).isRequired,
};
