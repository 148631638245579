import config from "../config";
import handleResponse from "./helper";
import { sessionService } from "redux-react-session";

export const userService = {
	login,
	logout,
	getLoggedInUser,
};

function login(code) {
	const url = `${config.apiUrl}/hpe_sso/api_authorize/?code=${code}`;
	const requestOptions = {
		method: 'GET'
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function getLoggedInUser(token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/users/profile`, requestOptions).then(handleResponse);
}

//Logout by deleting user session and redirect user to login page
function logout(history) {
	sessionService.deleteSession();
	sessionService.deleteUser();
}
