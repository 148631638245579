import config from "../config";
import { ActionTypes } from "../redux/constants/actionTypes";

export const analyticsService = {
	logEvent,
};

function logEvent(access, event, product_group_id = null, demo_id = null, calendar_event_id = null) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json", Authorization: "Bearer " + access },
		body: JSON.stringify({
			event: event,
			demo_id: demo_id,
			product_group_id: product_group_id,
			calendar_event_id: calendar_event_id,
		}),
	};
	console.log(ActionTypes.METRIC_SUBMIT + " " + event);
	return fetch(`${config.apiUrl}/analytics/log-event/`, requestOptions).then();
}
