import config from "../config";
import handleResponse from "./helper";

export const feedbackService = {
	submitFeedback,
};

function submitFeedback(user_id, rating, found_information, experience, comments, token) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
		body: JSON.stringify({
			rating: rating,
			found_information: found_information,
			experience: experience,
			comment: comments,
			user: { id: user_id },
		}),
	};

	return fetch(`${config.apiUrl}/users/feedback_save/`, requestOptions).then(handleResponse);
}
