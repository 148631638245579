import { sessionService } from "redux-react-session";

//Handle http response
function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401 || response.status === 403) {
				// auto logout if 401 response returned from api
				logout();
				// location.reload(true);
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

//Logout by deleting user session and redirect user to login page
function logout(history) {
	sessionService.deleteSession();
	sessionService.deleteUser();
}

export default handleResponse;
