import React, { useContext } from "react";
import { Box, Button, Main, ResponsiveContext, Heading } from "grommet";
import config from "../config";
import header_image from "../static/img/bg_competitive.jpg";

export const LandingPage = () => {
	const size = useContext(ResponsiveContext);

	const handleClick = () => {
		window.location.href = `${config.ssoAuthorizationUrl}?client_id=${config.ssoClientID}&response_type=code&scope=openid profile&redirect_uri=${config.ssoRedirectURL}`;
	};
	return (
		<Main
			background={{ image: `url(${header_image})`, size: "cover", dark: true }}
			height='100%'
			pad={{ vertical: "xlarge", horizontal: "large" }}
			width='100%'
			align='center'
			justify='between'
			gap='large'
			direction='row'
		>
			<Box
				pad={size === "xlarge" ? "large" : "small"}
				width={size !== "small" ? "large" : "100%"}>
				<Heading color='white' margin='none' level={1} pad='large'>
					HPE Hybrid Cloud Competitive Intelligence
				</Heading>
				<Box width='small' margin={{ top: "medium" }}>
					<Button label='Login' fontSize='18px' size='large' primary onClick={handleClick} />
				</Box>
			</Box>
		</Main>
	);
};

export default LandingPage;
