import React from "react";
import { Box, Heading, Header, Text, Button } from "grommet";
import header_image from "../../static/img/hpe_storage.jpg";

export const DashboardHead = ({ user, dashboardHeader, history, size }) => {
	const handleClick = () => {
		history.push("/learn-more");
	};

	return (
		<Header
			background={{ image: `url(${header_image})`, size: "cover" }}
			height='medium'
			pad='medium'
			justify='between'
			gap='large'
			direction='row'
		>
			<Box width={{ max: "large" }}>
				<Heading color='white' margin='none' level={4}>
					Hi {user && user.given_name}, Welcome to {dashboardHeader.dashboard_header_title}
				</Heading>
				<Text color='white' size='small' pad='medium'>
					{dashboardHeader.dashboard_header_content_line1}
				</Text>
				{size !== "small" ? (
					<Text color='white' size='small'>
						{dashboardHeader.dashboard_header_content_line2}
					</Text>
				) : (
					<Text />
				)}
				<Box width='small' margin={{ top: "small" }}>
					<Button primary label='Learn More' size='medium' onClick={handleClick} />
				</Box>
			</Box>
		</Header>
	);
};
