import { productGroupService } from "../../services";
import { ActionTypes } from "../constants/actionTypes";
import { alertError } from "./alertActions";

export const productGroupActions = {
	getProductGroupList,
	getProductGroup,
	getContentTypes,
};

function getProductGroupList(access) {
	return (dispatch) => {
		dispatch(request({}));
		productGroupService
			.getProductGroupList(access)
			.then((productGroupList) => {
				dispatch(success(productGroupList));
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.PRODUCT_GROUP_LIST_REQUEST, user };
	}
	function success(productGroupList) {
		return { type: ActionTypes.PRODUCT_GROUP_LIST_SUCCESS, productGroupList };
	}
	function failure(error) {
		return { type: ActionTypes.PRODUCT_GROUP_LIST_FAILURE, error };
	}
}

function getProductGroup(id, access) {
	return (dispatch) => {
		dispatch(request({}));
		productGroupService
			.getProductGroup(id, access)
			.then((productGroup) => {
				dispatch(success(productGroup));
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.PRODUCT_GROUP_REQUEST, user };
	}
	function success(productGroup) {
		return { type: ActionTypes.PRODUCT_GROUP_SUCCESS, productGroup };
	}
	function failure(error) {
		return { type: ActionTypes.PRODUCT_GROUP_FAILURE, error };
	}
}

function getContentTypes(access) {
	return (dispatch) => {
		dispatch(request({}));
		productGroupService
			.getContentTypes(access)
			.then((contentTypes) => {
				dispatch(success(contentTypes));
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.CONTENT_TYPES_REQUEST, user };
	}
	function success(contentTypes) {
		return { type: ActionTypes.CONTENT_TYPES_SUCCESS, contentTypes };
	}
	function failure(error) {
		return { type: ActionTypes.CONTENT_TYPES_FAILURE, error };
	}
}
