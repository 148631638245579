import { competetiveService } from "../../services";
import { ActionTypes } from "../constants/actionTypes";
import { alertError } from "./alertActions";

export const competetiveActions = {
    getCompetitors,
    getCompetitorProducts,
    getSingleClickTypes,
    getSingleClickResource,
    getResourceTypes,
    getResources
};

function getCompetitors(access) {
    return (dispatch) => {
        dispatch(request({}));
        competetiveService
            .getCompetitors(access)
            .then((Competitors) => {
                dispatch(success(Competitors));
            })
            .catch((err) => {
                console.error(err);
                dispatch(failure(err));
                dispatch(alertError(err));
            });
    };
    function request(user) {
        return { type: ActionTypes.COMPETITOR_REQUEST, user };
    }
    function success(competitors) {
        return { type: ActionTypes.COMPETITOR_SUCCESS, competitors };
    }
    function failure(error) {
        return { type: ActionTypes.COMPETITOR_FAILURE, error };
    }
}

function getCompetitorProducts(id, access) {
    return (dispatch) => {
        dispatch(request({}));
        competetiveService
            .getCompetitorProducts(id, access)
            .then((competitorProducts) => {
                dispatch(success(competitorProducts));
            })
            .catch((err) => {
                console.error(err);
                dispatch(failure(err));
                dispatch(alertError(err));
            });
    };
    function request(user) {
        return { type: ActionTypes.COMPETITOR_PRODUCTS_REQUEST, user };
    }
    function success(competitorProducts) {
        return { type: ActionTypes.COMPETITOR_PRODUCTS_SUCCESS, competitorProducts };
    }
    function failure(error) {
        return { type: ActionTypes.COMPETITOR_PRODUCTS_FAILURE, error };
    }
}
function getSingleClickTypes(access) {
    return (dispatch) => {
        dispatch(request({}));
        competetiveService
            .getSingleClickTypes(access)
            .then((singleClickTypes) => {
                dispatch(success(singleClickTypes));
            })
            .catch((err) => {
                console.error(err);
                dispatch(failure(err));
                dispatch(alertError(err));
            });
    };
    function request(user) {
        return { type: ActionTypes.SINGLE_CLICK_TYPE_REQUEST, user };
    }
    function success(singleclicktypes) {
        return { type: ActionTypes.SINGLE_CLICK_TYPE_SUCCESS, singleclicktypes };
    }
    function failure(error) {
        return { type: ActionTypes.SINGLE_CLICK_TYPE_FAILURE, error };
    }
}

function getSingleClickResource(id, access) {
    return (dispatch) => {
        dispatch(request({}));
        competetiveService
            .getSingleClickResources(access, id)
            .then((singleclickresources) => {
                dispatch(success(singleclickresources));
            })
            .catch((err) => {
                console.error(err);
                dispatch(failure(err));
                dispatch(alertError(err));
            });
    };
    function request(user) {
        return { type: ActionTypes.SINGLE_CLICK_REQUEST, user };
    }
    function success(singleclickResources) {
        return { type: ActionTypes.SINGLE_CLICK_SUCCESS, singleclickResources };
    }
    function failure(error) {
        return { type: ActionTypes.SINGLE_CLICK_FAILURE, error };
    }
}

function getResourceTypes(access) {
    return (dispatch) => {
        dispatch(request({}));
        competetiveService
            .getResourceTypes(access)
            .then((resourceTypes) => {
                dispatch(success(resourceTypes));
            })
            .catch((err) => {
                console.error(err);
                dispatch(failure(err));
                dispatch(alertError(err));
            });
    };
    function request(user) {
        return { type: ActionTypes.RESOURCE_TYPES_REQUEST, user };
    }
    function success(resourceTypes) {
        return { type: ActionTypes.RESOURCE_TYPES_SUCCESS, resourceTypes };
    }
    function failure(error) {
        return { type: ActionTypes.RESOURCE_TYPES_FAILURE, error };
    }
}

function getResources(comp_prod_id, resource_type_id, access) {
    return (dispatch) => {
        dispatch(request({}));
        competetiveService
            .getResources(comp_prod_id, resource_type_id, access)
            .then((resources) => {
                dispatch(success(resources));
            })
            .catch((err) => {
                console.error(err);
                dispatch(failure(err));
                dispatch(alertError(err));
            });
    };
    function request(user) {
        return { type: ActionTypes.RESOURCES_REQUEST, user };
    }
    function success(resources) {
        return { type: ActionTypes.RESOURCES_SUCCESS, resources };
    }
    function failure(error) {
        return { type: ActionTypes.RESOURCES_FAILURE, error };
    }
}