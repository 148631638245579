import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Layer, ResponsiveContext, Spinner, Tip } from "grommet";
import { ShareRounded } from "grommet-icons";
import { demoActions } from "../redux/actions";
import { SurveyCard } from "../components/survey";
import { NotificationLayer } from "../components/global-header";
import { analyticsService } from "../services";
import { LogEvents } from "../redux/constants/logEvents";
import { DemoCrumbs, DemoSidePanel, DemoEmbedContainer, LiveDemoContainer } from "../components/demo";

export const Demo = () => {
	const size = useContext(ResponsiveContext);
	const dispatch = useDispatch();
	const { id, product_group_id } = useParams();

	const session = useSelector((state) => {
		return state.session;
	});

	useEffect(() => {
		if (session && session.user && session.user.access) {
			dispatch(demoActions.getDemo(id, product_group_id, session.user.access));
		}
	}, [session, id, product_group_id, dispatch]);

	const demo = useSelector((state) => {
		return state.demo && state.demo.demo;
	});

	useEffect(() => {
		if (session && session.user && session.user.access && demo && demo.data) {
			if (demo.data.demo.demo_link && demo.data.demo.is_embedded === false) {
				window.open(demo.data.demo.demo_link);
				analyticsService.logEvent(session.user.access, LogEvents.DEMO, product_group_id, id);
				setSurveyPopup(true);
			}
		}
	}, [demo, session, id, product_group_id]);


	const [surveyPopup, setSurveyPopup] = useState(false);
	const [openSurveyNotification, setOpenSurveyNotification] = React.useState();

	const onOpenSurveyNotification = () => {
		setOpenSurveyNotification(true);
		setTimeout(() => {
			setOpenSurveyNotification(undefined);
		}, 3000);
	};

	const onCloseSurveyNotification = () => setOpenSurveyNotification(undefined);

	const openEmbedLink = (link) => {
		const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null;
	};

	return (
		<>
			{
				<Box pad='small'>
					{demo.requestInProgress && (
						<Layer position='center' background='none' style={{ boxShadow: "none" }}>
							<Spinner />
						</Layer>
					)}
					{surveyPopup && (
						<SurveyCard
							setSurveyPopup={setSurveyPopup}
							openSurveyPopup={surveyPopup}
							demo={demo.data.id}
							product_group_id={product_group_id}
							demoName={demo.data.demo.demo_title}
							setNotification={onOpenSurveyNotification}
						/>
					)}

					{openSurveyNotification && (
						<NotificationLayer
							notificationMessage={"Review submitted successfully!"}
							closeNotification={onCloseSurveyNotification}
						/>
					)}
					{demo.data &&
						<Box direction="row">
							<DemoCrumbs demo={demo.data} product_group_id={product_group_id} />
							{demo.data.is_embedded && (
								<Tip content="Open this demo in new tab and share it">
									<Box pad="small">
										<ShareRounded style={{ cursor: "pointer" }} onClick={() => openEmbedLink(demo.data.link)} />
									</Box>
								</Tip>
							)}
						</Box>}
					{demo.data && (
						<Box direction={size !== "small" ? "row" : "column"}>
							{demo.data.demo.demo_content_type !== "1" && <DemoSidePanel size={size} demo={demo.data} />}

							{demo.data.is_embedded && (
								<DemoEmbedContainer size={size} demo={demo.data} setSurveyPopup={setSurveyPopup} />
							)}
							{(demo.data.document_link || demo.data.upload_document) && demo.data.demo_link && (
								<LiveDemoContainer size={size} demo={demo.data} setSurveyPopup={setSurveyPopup} />
							)}
						</Box>
					)}
				</Box>
			}
		</>
	);
};

export default Demo;
