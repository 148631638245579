import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../config";

const PrivateRoute = ({ component, exact = false, path }) => {
	const userSession = useSelector((state) => {
		window.localStorage.setItem("path", window.location.pathname);
		return state.session;
	});
	return (
		<Route
			exact={exact}
			path={path}
			render={(props) =>
				userSession.authenticated ? (
					React.createElement(component, props)
				) : path === "/" ? (
					<Redirect to={{ pathname: "/index", state: { from: props.location } }} />
				) : (
					<Route
						component={() => {
							window.location.href = `${config.ssoAuthorizationUrl}?client_id=${config.ssoClientID}&response_type=code&scope=openid profile&redirect_uri=${config.ssoRedirectURL}`;
							return null;
						}}
					/>
				)
			}
		/>
	);
};

const { object, bool, string, func } = PropTypes;

PrivateRoute.propTypes = {
	component: func.isRequired,
	exact: bool,
	path: string.isRequired,
	location: object,
};

export default PrivateRoute;
