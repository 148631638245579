import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers/index";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { sessionService } from "redux-react-session";

const loggerMiddleware = createLogger({
	collapsed: true,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, undefined, composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)));

const options = { refreshOnCheckAuth: false, redirectPath: "/login", driver: "COOKIES" };
sessionService
	.initSessionService(store, options)
	.then(() => console.log("Redux React Session is ready and a session was refreshed from your storage"))
	.catch(() => console.log("Redux React Session is ready and there is no session in your storage"));

export default store;
