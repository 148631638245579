import { ActionTypes } from "../constants/actionTypes";

export const alertSuccess = (message) => {
	return {
		type: ActionTypes.ALERT_SUCCESS,
		message,
	};
};

export const alertError = (message) => {
	return { type: ActionTypes.ALERT_ERROR, message };
};

export const alertClear = () => {
	return { type: ActionTypes.ALERT_CLEAR };
};
