import { ActionTypes } from "../constants/actionTypes";
import { analyticsService, userService } from "../../services";
import { alertError } from "./alertActions";
import { sessionService } from "redux-react-session";
import { LogEvents } from "../constants/logEvents";

export const userActions = {
	login,
	logout,
};

function login(code, history) {
	return (dispatch) => {
		dispatch(request("Login"));
		userService.login(code).then(
			(userTokenResp) => {
				sessionService
					.saveSession(userTokenResp)
					.then(() => {
						const token = userTokenResp.data[0].access_token;
						userService
							.getLoggedInUser(token)
							.then((userProfile) => {
								userProfile["access"] = token;
								sessionService.saveUser({ ...userProfile });
								dispatch(success(userProfile));
								analyticsService.logEvent(token, LogEvents.LOGIN);
							})
							.then(() => {
								window.localStorage.setItem("path", "/");
								let path = window.localStorage.getItem("path");
								if (path === "/index") {
									history.push("/");
								} else {
									history.push(path);
								}
							});
					})
					.catch((err) => console.error(err));
			},
			(error) => {
				dispatch(failure(error));
				dispatch(alertError(error));
				history.push("/index");
			}
		);
	};

	function request(user) {
		return { type: ActionTypes.LOGIN_REQUEST, user };
	}
	function success(user) {
		return { type: ActionTypes.LOGIN_SUCCESS, user };
	}
	function failure(error) {
		return { type: ActionTypes.LOGIN_FAILURE, error };
	}
}

function logout() {
	userService.logout();
	return { type: ActionTypes.LOGOUT };
}
