import config from "../config";
import handleResponse from "./helper";

export const surveyService = {
	submitSurvey,
};

function submitSurvey(user_id, rating, comments, demo_id, token) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
		body: JSON.stringify({
			rating: rating,
			demo: demo_id,
			comment: comments,
		}),
	};

	return fetch(`${config.apiUrl}/demo/survey_save/${demo_id}`, requestOptions).then(handleResponse);
}
