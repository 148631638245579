import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardBody, Text, CardFooter, Card, Image, Box } from "grommet";
import styled from "styled-components";
import { useHistory } from "react-router";

const StyledCard = styled(Card)`
	transition: all 0.3s ease-in-out;
	border-style: solid;
	border-width: 1px;
	border-color: #efefef;
`;

export const DashboardCard = ({ card, ...rest }) => {
	const { id, name, description, count, page_link, image } = card;
	const [isFocused, setIsFocused] = useState(false);

	const MAX_DESC_LENGTH = 150;
	const history = useHistory();
	return (
		<StyledCard
			key={id}
			background='background-front'
			elevation={isFocused ? "medium" : "small"}
			onClick={() => {
				history.push(page_link);
			}}
			onBlur={() => setIsFocused(false)}
			onFocus={() => setIsFocused(true)}
			onMouseOut={() => setIsFocused(false)}
			onMouseOver={() => setIsFocused(true)}
			width='fill'
		>
			<CardBody gap='xsmall' align='start' flex='grow'>
				<Box height='xsmall' width='small'>
					<Image fit='contain' src={image} />
				</Box>
				<Text size='large' weight='bold' color='text-strong'>
					{name}
				</Text>
				{description.length > MAX_DESC_LENGTH ? (
					<Text size='small' numberOfLines={1} ellipsizeMode='end'>
						{" "}
						{`${description.substring(0, MAX_DESC_LENGTH)}...`}{" "}
					</Text>
				) : (
					<Text size='small' numberOfLines={1} ellipsizeMode='end'>
						{" "}
						{description}{" "}
					</Text>
				)}
			</CardBody>
			<CardFooter align='end' justify='end' background='background-back'>
				<Text size='xsmall' weight='bold' color='text-strong'>
					{count} items
				</Text>
			</CardFooter>
		</StyledCard>
	);
};

DashboardCard.propTypes = {
	card: PropTypes.shape({
		cta: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		title: PropTypes.string,
		description: PropTypes.string,
		active_items: PropTypes.string,
		page_link: PropTypes.string,
	}).isRequired,
};
