import { analyticsService, dashboardService } from "../../services";
import { ActionTypes } from "../constants/actionTypes";
import { LogEvents } from "../constants/logEvents";
import { alertError } from "./alertActions";

export const dashboardActions = {
	getDashboardHeader,
	getDashboardVideo,
	getCalendar,
};

function getDashboardHeader(access) {
	return (dispatch) => {
		dispatch(request({}));
		dashboardService
			.getDashboardHeader(access)
			.then((dashboardHeader) => {
				dispatch(success(dashboardHeader));
				analyticsService.logEvent(access, LogEvents.DASHBOARD);
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.DASHBOARD_HEADER_REQUEST, user };
	}
	function success(dashboardHeader) {
		return { type: ActionTypes.DASHBOARD_HEADER_SUCCESS, dashboardHeader };
	}
	function failure(error) {
		return { type: ActionTypes.DASHBOARD_HEADER_FAILURE, error };
	}
}

function getDashboardVideo(access) {
	return (dispatch) => {
		dispatch(request({}));
		dashboardService
			.getDashboardVideo(access)
			.then((dashboardVideo) => {
				dispatch(success(dashboardVideo));
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.DASHBOARD_VIDEO_REQUEST, user };
	}
	function success(dashboardVideo) {
		return { type: ActionTypes.DASHBOARD_VIDEO_SUCCESS, dashboardVideo };
	}
	function failure(error) {
		return { type: ActionTypes.DASHBOARD_VIDEO_FAILURE, error };
	}
}

function getCalendar(access) {
	return (dispatch) => {
		dispatch(request({}));
		dashboardService
			.getCalendar(access)
			.then((calendar) => {
				dispatch(success(calendar));
				analyticsService.logEvent(access, LogEvents.CALENDAR_LIST);
			})
			.catch((err) => {
				console.error(err);
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};
	function request(user) {
		return { type: ActionTypes.CALENDAR_REQUEST, user };
	}
	function success(calendar) {
		return { type: ActionTypes.CALENDAR_SUCCESS, calendar };
	}
	function failure(error) {
		return { type: ActionTypes.CALENDAR_FAILURE, error };
	}
}
