import React, { useContext } from "react";
import { Box, ResponsiveContext, Tabs, Tab, Grid, Text } from "grommet";
import { DemoCard } from ".";
import { useSelector } from "react-redux";

export const DemoListMain = (props) => {
	const size = useContext(ResponsiveContext);
	const history = props.history;
	const [index, setIndex] = [props.index, props.setIndex];

	const onActive = (nextIndex) => {
		history.location.hash = "#" + nextIndex;
		history.push(history.location.search + history.location.hash);
		setIndex(nextIndex);
	};

	const tabData = useSelector(() => {
		if (props.demoList.length && props.contentTypes.length) {
			let categories = props.demoList.map((demo) => {
				return String(demo.demo_content_type);
			});
			let featured = props.demoList.filter((demo) => demo.is_featured === true);

			let contentTypes = [];
			if (featured.length) {
				contentTypes = contentTypes.concat([{ id: 0, content_type: "Featured" }]);
			}
			contentTypes = contentTypes.concat(
				props.contentTypes.filter((type) => categories.includes(String(type.id)))
			);
			let tabDemoData = {};
			contentTypes.forEach((element) => {
				tabDemoData[element.content_type] = props.demoList.filter(
					(demo) => demo.demo_content_type === String(element.id)
				);
			});
			if (featured.length) {
				tabDemoData["Featured"] = props.demoList.filter((demo) => demo.is_featured === true);
			}
			let tabs = [];
			for (const [key, value] of Object.entries(tabDemoData)) {
				let key_val = contentTypes.filter((type) => type.content_type === key);
				tabs.push(
					<Tab title={key} key={key_val[0].id} index={key_val[0].id} gap='medium'>
						<Grid
							columns={size !== "small" ? "medium" : "100%"}
							rows={[["auto", "full"]]}
							gap='medium'
							pad='medium'
							fill
						>
							{value.map((demo) => {
								const demoDet = {
									id: demo.id,
									key: key,
									type: contentTypes.filter((type) => type.id === parseInt(demo.demo_content_type))[0]
										.content_type,
									image: demo.demo_thumbnail,
									is_featured: demo.is_featured,
									description: demo.demo_header_info,
									is_survey_required: demo.is_survey_required,
									name: demo.demo_title,
									survey_rating: demo.survey_rating,
									demo_link: demo.demo_link,
									is_embedded: demo.is_embedded,
									page_link: `/product-group/${props.product_group_id}/demo/${demo.id}`,
								};
								return (
									<DemoCard
										key={demoDet.id}
										card={demoDet}
										product_group_id={props.product_group_id}
										access={props.access}
										onClick={() => {
											history.push(demoDet.page_link);
										}}
										setSurveyPopup={props.setSurveyPopup}
										setDemo={props.setDemo}
									/>
								);
							})}
						</Grid>
					</Tab>
				);
			}
			return tabs;
		}
	});

	return (
		<Box>
			{tabData ? (
				<Tabs activeIndex={index} onActive={onActive} justify='start'>
					{tabData}
				</Tabs>
			) : (
				<Box>
					<Text>No Search Results</Text>
				</Box>
			)}
		</Box>
	);
};
