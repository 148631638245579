
import { Box, Card, CardBody, Text } from "grommet"
export const CustomCard = ({ title, description, image_url, onClickHandler, border, color }) => {
    return <>
        <Card border={border ? {
            color: `${color}`,
        } : null} onClick={
            () =>
                onClickHandler()

        }>
            <CardBody direction="row" gap="medium" align="center">
                {image_url ? <Box width={"46px"} height={"46px"} background={{ image: `url(${image_url})`, size: "contain", color: 'white' }} ></Box> : <></>}
                <Box>
                    <Text size="large" weight={"bold"}>
                        {title}
                    </Text>
                    <Text size="small">
                        {description}
                    </Text>
                </Box>
            </CardBody>
        </Card>
    </>
}