import React from 'react'
import { Box, Header, Heading, Text, Image } from 'grommet';

export const PageNotFound = () => {
    return (
        <Box fill align="center">
            <Header >
                <Heading size="small">Error 404</Heading>
                <Text>Sorry, we couldn't find what you were looking for.</Text>
            </Header>
            <Image src="https://developer.hpe.com/img/hpe-dev-it-monster-worried.svg"
                style={{
                    width: "20%",
                }}
            />
        </Box>
    )
}
