import config from "../config";
import handleResponse from "./helper";


export const competetiveService = {
	getCompetitors,
	getCompetitorById,
	getCompetitorProducts,
	getResourceTypes,
	getResourceTypeById,
	getResources,
	getCompetitorProductById,
	submitFeedback,
	getSingleClickResources,
	getSingleClickTypes

}

function getCompetitors(token) {
	const requestOptions = {
		method: "GET",
		//headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/competitors/competitors-list/`, requestOptions).then(handleResponse);
}

function getCompetitorById(token, id) {
	const requestOptions = {
		method: "GET",
		//headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/competitors/competitor/${id}`, requestOptions).then(handleResponse);
}

function getCompetitorProducts(id, token) {
	const requestOptions = {
		method: "GET",
		// headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/competitors/competitor-products/${id}`, requestOptions).then(handleResponse);
}

function getCompetitorProductById(token, id) {
	const requestOptions = {
		method: "GET",
		// headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/competitors/competitor-product-by-id/${id}`, requestOptions).then(handleResponse);
}


function getResourceTypes(token) {
	const requestOptions = {
		method: "GET",
		// headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/resources/resource-types/`, requestOptions).then(handleResponse);
}

function getResourceTypeById(token, id) {
	const requestOptions = {
		method: "GET",
		// headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/resources/resource-types/${id}`, requestOptions).then(handleResponse);
}

function getResources(comp_prod_id, token) {
	const requestOptions = {
		method: "GET",
		//headers: {Authorization: "Bearer " + token},
	}
	return fetch(`${config.apiUrl}/resources/get-resources/?comp_prod_id=${comp_prod_id}`, requestOptions).then(handleResponse);
}

function getSingleClickTypes(token) {
	const requestOptions = {
		method: "GET",
		//headers: {Authorization: "Bearer " + token},
	}
	return fetch(`${config.apiUrl}/competitors/competitor-single-click-types/`, requestOptions).then(handleResponse);
}

function getSingleClickResources(token, id) {
	const requestOptions = {
		method: "GET",
		// headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/competitors/competitor-single-click-resources/${id}`, requestOptions).then(handleResponse);
}

function submitFeedback(upload_document, form) {
	const requestOptions = {
		method: "POST",
		body: form
	};
	console.log({ upload_document })
	return fetch(`${config.apiUrl}/competitors/competitor-user-feedback/`, requestOptions).then(handleResponse);
}