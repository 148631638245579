import config from "../config";
import handleResponse from "./helper";

export const dashboardService = {
	getDashboardHeader,
	getDashboardVideo,
	getCalendar,
};

function getDashboardHeader(token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/dashboard/dashboard-header/`, requestOptions).then(handleResponse);
}

function getDashboardVideo(token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/dashboard/help-video/`, requestOptions).then(handleResponse);
}

function getCalendar(token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/dashboard/events/`, requestOptions).then(handleResponse);
}
