import React from "react";
import { Grommet, Box } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { EmbedDemo } from "../pages";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

export const PublicLayout = () => {
    return (
        <Grommet theme={hpe} full>
            <Router>
                <>
                    <Box
                        gridArea='main'
                        background='background'
                        justify='start'
                        fill='horizontal'
                        margin={{ horizontal: "auto" }}
                        width={{ max: "1800px" }}
                        height="100%"
                    >
                        <Switch>
                            <Route path='/embed/demo/:id' component={EmbedDemo} />
                        </Switch>
                    </Box>
                </>
            </Router>
        </Grommet>
    );
};

export default PublicLayout;
