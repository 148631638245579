import React, { useContext, useEffect } from "react";
import { Box, Button, Form, FormField, Heading, Layer, Text, ResponsiveContext } from "grommet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Rating } from "@material-ui/lab";
import { surveyActions } from "../../redux/actions/surveyActions";
import { useHistory } from "react-router-dom";
import { Checkmark, CircleAlert, FormClose } from "grommet-icons";
import { analyticsService } from "../../services";
import { LogEvents } from "../../redux/constants/logEvents";

export const SurveyCard = (props) => {
	const size = useContext(ResponsiveContext);
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector((state) => {
		return state.session.user;
	});

	const session = useSelector((state) => {
		return state.session;
	});

	useEffect(() => {
		if (props.setSurveyPopup) {
			analyticsService.logEvent(
				session.user.access,
				LogEvents.SURVEY_STARTED,
				props.product_group_id,
				props.demo.id
			);
		}
	}, [props.setSurveyPopup, props.demo.id, props.product_group_id, session.user.access]);

	const [formValues, setFormValues] = React.useState({
		rating: 0,
		comments: "",
	});

	const [ratingValue, setRatingValue] = React.useState(0);
	const [surveyError, setSurveyError] = React.useState(false);
	const [surveySuccess, setSurveySuccess] = React.useState(false);
	const [commentsValue, setCommentsValue] = React.useState("");

	const onClose = () => {
		props.setSurveyPopup(undefined);
	};

	const onSubmit = ({ value, touched }) => {
		if (ratingValue === 0 && commentsValue === "") {
			setSurveyError(true);
			setSurveySuccess(undefined);
		} else {
			dispatch(
				surveyActions.submitSurvey(
					user.user.id,
					ratingValue,
					commentsValue,
					props.demo,
					session.user.access,
					props.product_group_id,
					history
				)
			);
			setRatingValue(0);
			setCommentsValue("");
			setSurveySuccess(true);
			setSurveyError(undefined);
			props.setNotification();
			onClose();
		}
	};

	return (
		props.openSurveyPopup && (
			<Layer id='survey-layer' position='center' onClickOutside={onClose} onEsc={onClose}>
				<Box width={size !== "small" ? "medium" : "fill"} height='100%' pad='small' align='center'>
					<Box alignSelf='end'>
						<FormClose size='medium' onClick={onClose} />
					</Box>
					<Box pad='small' round='5px' width={{ max: "medium" }}>
						<Form
							validate='blur'
							value={formValues}
							onChange={setFormValues}
							messages={{
								required: "This is a required field.",
							}}
							onSubmit={({ value, touched }) => onSubmit({ value, touched })}
						>
							<Heading margin='none' level={2} pad='large'>
								Survey for {props.demoName}
							</Heading>

							<FormField name='rating' htmlFor='rating-id' label='' style={{ border: "none" }}>
								<Rating
									id='rating-id'
									size='large'
									name='rating'
									value={ratingValue}
									onChange={(event, newValue) => {
										setRatingValue(newValue);
									}}
								/>
							</FormField>
							<FormField name='comments' htmlFor='comments-id' label='Feedback'>
								<textarea
									id='comments-id'
									name='comments'
									rows='4'
									value={commentsValue}
									onChange={(event) => setCommentsValue(event.target.value)}
								></textarea>
							</FormField>
							{surveyError && (
								<Box
									animation='fadeIn'
									align='center'
									background='validation-critical'
									direction='row'
									gap='xsmall'
									margin={{ top: "medium", bottom: "medium" }}
									pad='small'
									round='4px'
								>
									<CircleAlert size='small' color='red' />
									<Text size='xsmall' color='red'>
										Do not provide empty survey!
									</Text>
								</Box>
							)}
							{surveySuccess && (
								<Box
									animation='fadeIn'
									align='center'
									background='validation-critical'
									direction='row'
									gap='xsmall'
									margin={{ top: "small", bottom: "small" }}
									pad='small'
									round='4px'
								>
									<Checkmark size='small' />
									<Text size='xsmall' color='green'>
										Successfully submitted the survey!
									</Text>
								</Box>
							)}
							<Box direction='row' gap='medium'>
								<Button type='submit' primary label='Submit' />
							</Box>
						</Form>
					</Box>
				</Box>
			</Layer>
		)
	);
};

export default SurveyCard;
