import React from "react";
import { Box, Text, Button, Layer } from "grommet";
import { StatusGood, StatusInfo, FormClose } from "grommet-icons";

export const NotificationLayer = (props) => {
	return (
		<Layer
			position='bottom'
			modal={false}
			margin={{ vertical: "medium", horizontal: "small" }}
			onEsc={props.closeNotification}
			responsive={false}
			plain
		>
			<Box
				align='center'
				direction='row'
				gap='small'
				justify='between'
				round='medium'
				elevation='medium'
				pad={{ vertical: "xsmall", horizontal: "small" }}
				background={props.info ? "status-warning" : "status-ok"}
			>
				<Box align='center' direction='row' gap='xsmall'>
					{!props.info && <StatusGood />}
					{props.info && <StatusInfo />}
					<Text>{props.notificationMessage}</Text>
				</Box>
				<Button icon={<FormClose />} onClick={props.closeNotification} plain />
			</Box>
		</Layer>
	);
};

export default NotificationLayer;
