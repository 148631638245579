export const LogEvents = {
	LOGIN: "1",
	LOGOUT: "2",
	DEMO: "3",
	SURVEY_STARTED: "4",
	SURVEY_FINISHED: "5",
	FEEDBACK_STARTED: "6",
	FEEDBACK_FINISHED: "7",
	DASHBOARD: "8",
	DEMO_LIST: "9",
	CALENDAR_LIST: "10",
	CALENDAR_EVENT: "11",
};
