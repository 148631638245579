import React, { useContext, useEffect } from "react";
import { Box, Button, Form, FormField, RadioButtonGroup, Heading, Layer, Text, ResponsiveContext } from "grommet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Rating } from "@material-ui/lab";
import { feedbackActions } from "../../redux/actions/feedbackActions";
import { useHistory } from "react-router-dom";
import { Checkmark, CircleAlert, FormClose } from "grommet-icons";
import { analyticsService } from "../../services";
import { LogEvents } from "../../redux/constants/logEvents";

export const FeedbackCard = (props) => {
	const size = useContext(ResponsiveContext);
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector((state) => {
		return state.session.user;
	});

	const session = useSelector((state) => {
		return state.session;
	});

	useEffect(() => {
		if (props.setPopup) {
			analyticsService.logEvent(session.user.access, LogEvents.FEEDBACK_STARTED);
		}
	}, [props.setPopup, session.user.access]);

	const [formValues, setFormValues] = React.useState({
		rating: 0,
		found_information: false,
		experience: "",
		comments: "",
	});

	const [ratingValue, setRatingValue] = React.useState(0);
	const [radioValue, setRadioValue] = React.useState(false);
	const [feedbackError, setFeedbackError] = React.useState(false);
	const [feedbackSuccess, setFeedbackSuccess] = React.useState(false);
	const [experienceValue, setExperienceValue] = React.useState("");
	const [commentsValue, setCommentsValue] = React.useState("");
	const onClose = () => {
		props.setPopup(undefined);
	};

	const onSubmit = ({ value, touched }) => {
		if (ratingValue === 0 && experienceValue === "" && commentsValue === "") {
			setFeedbackError(true);
			setFeedbackSuccess(undefined);
		} else {
			dispatch(
				feedbackActions.submitFeedback(
					user.user.id,
					ratingValue,
					radioValue,
					experienceValue,
					commentsValue,
					session.user.access,
					history
				)
			);
			setRatingValue(0);
			setRadioValue(false);
			setExperienceValue("");
			setCommentsValue("");
			setFeedbackSuccess(true);
			setFeedbackError(undefined);
			props.setNotification();
			onClose();
		}
	};

	return (
		props.openPopup && (
			<Layer id='feedback-layer' position='center' onClickOutside={onClose} onEsc={onClose}>
				<Box width={size !== "small" ? "medium" : "fill"} height='100%' pad='small'>
					<Box alignSelf='end'>
						<FormClose size='medium' onClick={onClose} />
					</Box>
					<Box align='center' pad='medium' round='5px'>
						<Form
							validate='blur'
							value={formValues}
							onChange={setFormValues}
							messages={{
								required: "This is a required field.",
							}}
							onSubmit={({ value, touched }) => onSubmit({ value, touched })}
						>
							<Heading margin='none' level={2} pad='large'>
								Feedback
							</Heading>

							<FormField
								name='rating'
								htmlFor='rating-id'
								label='Overall Rating'
								style={{ border: "none" }}
							>
								<Rating
									id='rating-id'
									size='large'
									name='rating'
									value={ratingValue}
									onChange={(event, newValue) => {
										setRatingValue(newValue);
									}}
								/>
							</FormField>
							<FormField
								name='found_information'
								htmlFor='found_information-id'
								label='Were you able to find the information you were looking for on this portal?'
							>
								<RadioButtonGroup
									id='found_information-id'
									name='found_information'
									direction='row'
									size='xsmall'
									options={["Yes", "No"]}
									value={radioValue}
									onChange={(event) => setRadioValue(event.target.value)}
								/>
							</FormField>
							<FormField
								name='experience'
								htmlFor='experience-id'
								label='How did your experience compare to your expectations?'
							>
								<textarea
									id='experience-id'
									name='experience'
									rows='4'
									value={experienceValue}
									onChange={(event) => setExperienceValue(event.target.value)}
								></textarea>
							</FormField>
							<FormField name='comments' htmlFor='comments-id' label='Other comments and suggestions'>
								<textarea
									id='comments-id'
									name='comments'
									rows='4'
									value={commentsValue}
									onChange={(event) => setCommentsValue(event.target.value)}
								></textarea>
							</FormField>
							{feedbackError && (
								<Box
									animation='fadeIn'
									align='center'
									background='validation-critical'
									direction='row'
									gap='xsmall'
									margin={{ top: "medium", bottom: "medium" }}
									pad='small'
									round='4px'
								>
									<CircleAlert size='small' color='red' />
									<Text size='xsmall' color='red'>
										Do not provide empty Feedback!
									</Text>
								</Box>
							)}
							{feedbackSuccess && (
								<Box
									animation='fadeIn'
									align='center'
									background='validation-critical'
									direction='row'
									gap='xsmall'
									margin={{ top: "small", bottom: "small" }}
									pad='small'
									round='4px'
								>
									<Checkmark size='small' />
									<Text size='xsmall' color='green'>
										Successfully submitted the feedback!
									</Text>
								</Box>
							)}
							<Box direction='row' gap='medium'>
								<Button type='submit' primary label='Submit' />
							</Box>
						</Form>
					</Box>
				</Box>
			</Layer>
		)
	);
};

export default FeedbackCard;
