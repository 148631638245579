import { ActionTypes } from "../constants/actionTypes";

const initialState = {
	dashboardHeader: { data: null, requestInProgress: false, error: null },
	dashboardVideo: { data: null, requestInProgress: false, error: null },
	productGroupList: { data: null, requestInProgress: false, error: null },
	calendar: { data: null, requestInProgress: false, error: null },
};

export function dashboard(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.DASHBOARD_HEADER_REQUEST:
			return { ...state, dashboardHeader: { requestInProgress: true } };
		case ActionTypes.DASHBOARD_HEADER_SUCCESS:
			return { ...state, dashboardHeader: { data: action.dashboardHeader, requestInProgress: false } };
		case ActionTypes.DASHBOARD_HEADER_FAILURE:
			return { ...state, contentTypes: { requestInProgress: false, error: action.error } };
		case ActionTypes.DASHBOARD_VIDEO_REQUEST:
			return { ...state, dashboardVideo: { requestInProgress: true } };
		case ActionTypes.DASHBOARD_VIDEO_SUCCESS:
			return { ...state, dashboardVideo: { data: action.dashboardVideo, requestInProgress: false } };
		case ActionTypes.DASHBOARD_VIDEO_FAILURE:
			return { ...state, contentTypes: { requestInProgress: false, error: action.error } };
		case ActionTypes.PRODUCT_GROUP_LIST_REQUEST:
			return { ...state, productGroupList: { requestInProgress: true } };
		case ActionTypes.PRODUCT_GROUP_LIST_SUCCESS:
			return { ...state, productGroupList: { data: action.productGroupList, requestInProgress: false } };
		case ActionTypes.PRODUCT_GROUP_LIST_FAILURE:
			return { ...state, contentTypes: { requestInProgress: false, error: action.error } };
		case ActionTypes.CALENDAR_REQUEST:
			return { ...state, calendar: { requestInProgress: true } };
		case ActionTypes.CALENDAR_SUCCESS:
			return { ...state, calendar: { data: action.calendar, requestInProgress: false } };
		case ActionTypes.CALENDAR_FAILURE:
			return { ...state, contentTypes: { requestInProgress: false, error: action.error } };
		default:
			return state;
	}
}
