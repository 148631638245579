import React, { useContext } from "react";
import { Header, ResponsiveContext } from "grommet";
import { AppIdentity, HeaderNav } from ".";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const GlobalHeader = () => {
	const size = useContext(ResponsiveContext);
	const history = useHistory();
	const userSession = useSelector((state) => {
		return state.session;
	});
	return (
		<>
			<Header
				align='center'
				background='background-front'
				justify='between'
				margin='auto'
				fill='horizontal'
				width={{ max: "1800px" }}
				pad={{
					horizontal: size !== "small" ? "xlarge" : "20px",
					vertical: "xsmall",

				}}
			>
				<AppIdentity
					title={'Hybrid Cloud Competitive Intelligence Application'}
					brand='hpe'
					onClick={() => {
						history.push("/");
					}}
				/>
				{userSession.authenticated && <HeaderNav />}
			</Header>
		</>
	);
};

export default GlobalHeader;
