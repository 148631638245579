import { ActionTypes } from "../constants/actionTypes";

const initialState = {
	productGroup: { data: null, requestInProgress: false, error: null },
	contentTypes: { data: null, requestInProgress: false, error: null },
};

export function productGroup(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.PRODUCT_GROUP_REQUEST:
			return { ...state, productGroup: { requestInProgress: true } };
		case ActionTypes.PRODUCT_GROUP_SUCCESS:
			return { ...state, productGroup: { data: action.productGroup, requestInProgress: false } };
		case ActionTypes.PRODUCT_GROUP_FAILURE:
			return { ...state, productGroup: { requestInProgress: false, error: action.error } };
		case ActionTypes.CONTENT_TYPES_REQUEST:
			return { ...state, contentTypes: { requestInProgress: true } };
		case ActionTypes.CONTENT_TYPES_SUCCESS:
			return { ...state, contentTypes: { data: action.contentTypes, requestInProgress: false } };
		case ActionTypes.CONTENT_TYPES_FAILURE:
			return { ...state, contentTypes: { requestInProgress: false, error: action.error } };
		default:
			return state;
	}
}
