import React, { useState } from "react";
import { Box, Image, Text, Spinner, Button } from 'grommet';
import ReactTooltip from 'react-tooltip';
import styled, { keyframes } from "styled-components";

export const ScreenCard = ({ slide, boxOnClick, isLastScreen, setCurrentPosition, setisLastScreen }) => {
    const highlighted_area = slide.highlighted_area.split(',');
    const tooltip_areas = slide.tooltip_areas;
    const left = highlighted_area[0];
    const top = highlighted_area[1];
    const width = highlighted_area[2];
    const height = highlighted_area[3];

    const blinkingEffect = () => {
        return keyframes`
                50% {
                opacity: 0;
                }
            `;
    }

    const AnimatedComponent = styled.div`animation: ${blinkingEffect} 1s linear infinite;animation-iteration-count: 3`
    const [loading, setLoading] = useState(true);
    const listAreas = tooltip_areas.map((area) =>
        <TooltipArea area={area} key={area.id} />
    );

    const imageLoaded = () => {
        setLoading(false);
    }

    const tryAgain = () => {
        setisLastScreen(false);
        setCurrentPosition(0);
    }

    return <>{
        <Box style={{ position: 'relative', left: 0, top: 0, width: "100%" }} key={slide.id}>
            <Box
                style={{
                    display: loading ? "block" : "none",
                    position: "absolute",
                    padding: 0,
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    textAlign: "center !important",
                    verticalAlign: "middle",
                }}
                size="cover"
            >
                <Spinner style={{
                    marginTop: "20%",
                    marginLeft: "47%",
                }} />
            </Box>

            <Image src={slide.image} fit='cover'
                style={{
                    width: "100%",
                    height: "auto",
                    display: loading ? "none" : "block"
                }}
                onLoad={imageLoaded}
                activeIndex={slide.id}
            />

            {!isLastScreen && !loading &&
                < AnimatedComponent >
                    <ReactTooltip id='global' textColor='#ffffff' backgroundColor='#01A982'>
                        <Text weight="bold" style={{ fontSize: "12px" }}>{slide.tooltip}</Text>
                    </ReactTooltip>
                    <Box
                        align="center"
                        border={{ color: '#7630EA', size: 'medium' }}
                        pad="large"
                        style={{
                            position: "absolute",
                            padding: 0,
                            left: `${left}% `,
                            top: `${top}% `,
                            width: `${width}% `,
                            height: `${height}% `,
                            boxShadow: "rgb(0 0 0 / 50%) 0px 6px 10px"
                        }}
                        onClick={boxOnClick}
                        data-tip data-for='global'
                    >
                    </Box>
                </AnimatedComponent>
            }
            {listAreas}

            {isLastScreen &&
                <Box style={{
                    position: "absolute",
                    padding: 0,
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    color: "#fff!important",
                    textAlign: "center !important",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                    fontSize: "100%",
                    border: "none!important",
                    background: "rgba(25, 25, 25, .5)",
                }}>

                    <Box background="brand" style={{
                        marginTop: "20%",
                        display: "block",
                        color: "#fdfafa",
                        textTransform: "uppercase",
                        overflow: "hidden",
                        position: "relative",
                        zIndex: 0,
                        padding: "20px",
                        textAlign: "center",
                    }}> <Box>Demo is completed !</Box>
                        <Button primary style={{ fontSize: "13px", background: "#7630EA", padding: "5px" }} onClick={tryAgain}>Try again</Button>
                    </Box>

                </Box>
            }
        </Box>
    }</>;
};
export default ScreenCard;

const TooltipArea = ({ area }) => {
    const highlighted_area = area.area.split(',');
    const left = highlighted_area[0];
    const top = highlighted_area[1];
    const width = highlighted_area[2];
    const height = highlighted_area[3];
    const tooltid = area.id + "_tooltip";

    return (<>
        <ReactTooltip id={tooltid} textColor='#ffffff' backgroundColor='#01A982'>
            <span>{area.text}</span>
        </ReactTooltip>
        <Box
            key={area.id}
            align="center"
            pad="large"
            style={{
                position: "absolute",
                padding: 0,
                left: `${left}% `,
                top: `${top}% `,
                width: `${width}% `,
                height: `${height}% `,
                hover: {
                    boxShadow: "rgb(0 0 0 / 50%) 0px 6px 10px"
                }
            }}
            data-tip
            data-for={tooltid}
        >
        </Box>
    </>
    );
}
