import { Page, PageContent, PageHeader, Card, CardHeader, Box, Heading, Menu, Text, CardBody, NameValueList, NameValuePair } from 'grommet'
import { MoreVertical, } from 'grommet-icons';
import React from 'react'
import { PieChart } from '../components/competetive/PieChart';

function CompetitorReportPage() {
    const DashboardCardHeader = ({ title, level, subtitle, menuItems }) => (
        <Box direction="row" align="start" justify="between" fill>
            <Box>
                <Heading margin="none" level={level}>
                    {title}
                </Heading>
                {subtitle && <Text>{subtitle}</Text>}
            </Box>
            {menuItems && (
                <Menu
                    icon={<MoreVertical />}
                    items={menuItems}
                    dropAlign={{ top: 'bottom', right: 'right' }}
                />
            )}
        </Box>
    );
    const StatusCard = ({ children, title, menuItems, ...rest }) => {
        return (<>
            <Card {...rest}>
                <CardHeader
                    pad={{ top: "small", left: "small", bottom: 'small' }}
                >
                    <DashboardCardHeader title={title} level={3} menuItems={menuItems} />
                </CardHeader>
                <CardBody>
                    <NameValueList
                        valueProps={{ width: ['xsmall', 'auto'] }}
                        pairProps={{ direction: 'column' }}
                        layout="grid"
                    >
                        {children}
                    </NameValueList>
                </CardBody>
            </Card>
        </>);
    }
    const Content = () => {
        return (
            <>
                <StatusCard title={"Documents Summary"} menuItems={[{ label: 'Move', onClick: () => { } },
                { label: 'Share', onClick: () => { } },]}>
                    <NameValuePair name={<Box
                        direction="row"
                        align="center"
                        gap="small"
                        margin={{ bottom: 'xxsmall' }}
                    >
                        <Text size={'small'}>

                            {"Total Resources"}
                        </Text>
                    </Box>}>
                        <Text >100</Text>
                    </NameValuePair>
                    <NameValuePair name={<Box
                        direction="row"
                        align="center"
                        gap="small"
                        // margin is needed to keep consistent with the spacing
                        // delivered by the theme when name is typeof 'string'
                        // https://github.com/grommet/grommet/blob/db5be926eb7c2f791534f02dd55b0f9997e959db/src/js/themes/base.js#L1072
                        margin={{ bottom: 'xxsmall' }}
                    >
                        <Text size={'small'}>

                            {"Total Visits"}
                        </Text>
                    </Box>}>
                        <Text >2000</Text>
                    </NameValuePair>
                    <NameValuePair name={<Box
                        direction="row"
                        align="center"
                        gap="small"
                        // margin is needed to keep consistent with the spacing
                        // delivered by the theme when name is typeof 'string'
                        // https://github.com/grommet/grommet/blob/db5be926eb7c2f791534f02dd55b0f9997e959db/src/js/themes/base.js#L1072
                        margin={{ bottom: 'xxsmall' }}
                    >
                        <Text size={'small'}>

                            {"Feedback recived"}
                        </Text>
                    </Box>}>
                        <Text >100</Text>
                    </NameValuePair>
                </StatusCard>
                <StatusCard title={" Visits and Clicks"} menuItems={[{ label: 'Move', onClick: () => { } },
                { label: 'Share', onClick: () => { } },]}>
                    <PieChart />
                </StatusCard>
            </>
        )
    }
    return (
        <>
            <Page>
                <PageContent>
                    <PageHeader>Documents Report</PageHeader>
                    <Content />
                </PageContent>
            </Page>
        </>
    )


}

export default CompetitorReportPage