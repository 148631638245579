import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, FileInput, Form, FormField, Select, Header, Heading, TextArea, TextInput, DropButton, Nav, ResponsiveContext, Text, Layer } from "grommet";
import { MoreVertical, Logout, FormNext } from "grommet-icons";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { FeedbackCard } from "../../feedback";
import { NotificationLayer } from ".";
import { competetiveService } from "../../../services";

export const HeaderNav = () => {
	// const user = useSelector((state) => {
	// 	return state.session.user;
	// });
	const size = useContext(ResponsiveContext);
	const [open, setOpen] = useState();

	const [openPopup, setOpenPopup] = useState(false);
	const [openNotification, setOpenNotification] = React.useState();

	const onOpenNotification = () => {
		setOpenNotification(true);
		setTimeout(() => {
			setOpenNotification(undefined);
		}, 3000);
	};
	const onCloseNotification = () => setOpenNotification(undefined);

	const [showModal, setShowModal] = useState(false);
	function onClickHandler() {
		setShowModal(false)
	}
	return (
		<>
			{openPopup && (
				<FeedbackCard setPopup={setOpenPopup} openPopup={openPopup} setNotification={onOpenNotification} />
			)}
			<Nav align='center' direction='row' gap='small'>
				{size !== "xsmall" && (
					<>
						<Button
							size={size === "small" ? "small" : "medium"}
							a11yTitle='Home'
							primary
							label='Competitive Feedback'
							onClick={() => {
								setShowModal(true);
							}}
						/>
					</>
				)}

				{openNotification && (
					<NotificationLayer
						notificationMessage={"Feedback submitted successfully!"}
						closeNotification={onCloseNotification}
					/>
				)}
				<DropButton
					dropContent={<UserDetails size={size} />}
					dropProps={{ align: { top: "bottom", right: "right" } }}
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					icon={<MoreVertical />}
				>
					{/* <Stack anchor="top-right"> */}
					{/*notification stack */}
					{/* <Button icon={<MoreVertical />} /> */}
					{/* <Box background="orange" pad={{ horizontal: 'xxsmall' }} round>
							<Text size="xsmall">4</Text>
						</Box> */}
					{/* </Stack> */}
					{/* {user && (
					<Avatar background='blue!' flex={false}>
						{user.family_name && user.given_name && (
							<Text size='large' color='text-strong'>
								{user.given_name[0] + user.family_name[0]}
							</Text>
						)}
					</Avatar>
				)} */}
				</DropButton>
				{showModal &&
					<Layer>
						<FormForStory onClickHandler={onClickHandler} />
					</Layer>}
			</Nav>
		</>
	);
};
const FormForStory = ({ onClickHandler, toastHandler }) => {
	const size = useContext(ResponsiveContext);
	const user = useSelector((state) => {
		return state.session.user;
	});
	const competitors = useSelector((state) => { return state.competitiveDash.competitors })
	const [formValues, setFormValues] = React.useState({
	});
	const [numFiles, setNumFiles] = useState(0);
	const onFormChange = value => {
		setFormValues(value);
	};
	// eslint-disable-next-line no-unused-vars
	const onSubmit = ({ value, touched }) => {
		// Your submission logic here
		var form = new FormData()
		form.append("user", user.user.id)
		let indexOfCompetitor = competitorsList.indexOf(value["competitor"])
		let competitorId = competitorsIdList[indexOfCompetitor]
		form.append("competitor_id", competitorId)
		form.append("feedback", value.comments)
		if (value.fileinput) {
			form.append("upload_document", value.fileinput[0], value.fileinput[0].name)
		}
		else {
			form.append("upload_document", value.fileinput)
		}
		competetiveService.submitFeedback(user.id, competitorId, value.comments, value.fileinput, form)
		onClickHandler()
	};

	const [competitorsList, SetCompetitorsList] = useState([])
	const [competitorsIdList, SetCompetitorsIdList] = useState([])
	useEffect(() => {
		if (competitors.data) {
			let sortedArray = competitors.data
			sortedArray.sort(function (a, b) {
				var compA = a.competitor_name.toLowerCase();
				var compB = b.competitor_name.toLowerCase();
				return (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
			})
			sortedArray.forEach(element => {
				SetCompetitorsList(prev => [...prev, element["competitor_name"]])
				SetCompetitorsIdList(prev => [...prev, element["id"]])
			});
		}
	}, [competitors])
	useEffect(() => {
		if (user) {
			setFormValues({
				name: user.name,
				email: user.user.email,
				fileinput: '',
				comments: ''
			})
		}
	}, [user])
	return (
		<>
			<Box gap="medium" width="medium" pad={"medium"}>
				<Header
					direction="column"
					align="start"
					gap="xxsmall"
					pad={{ horizontal: 'xxsmall' }}
				>
					<Heading level={2} margin="none">
						Tell Us Your Story
					</Heading>
				</Header>
				<Box
					// Padding used to prevent focus from being cutoff
					pad={{ horizontal: 'xxsmall' }}
				>
					<Form
						messages={{
							required: 'This is a required field.',
						}}
						onSubmit={({ value, touched }) => onSubmit({ value, touched })}
						value={formValues}
						onChange={onFormChange}
						method="post"
						encType="multipart/form-data"
					>
						<FormField
							required
							// error="Provide a unique name."
							htmlFor="name"
							name="name"
							label="Name"
						>
							<TextInput id="name" name="name" value={user.name} />
						</FormField>
						<FormField required htmlFor="email" name="email" label="Email">
							<TextInput id="email" name="email" value={user.user.email} />
						</FormField>
						<FormField
							required
							htmlFor="competitor__input"
							name="competitor"
							label="Competitor"
						>
							<Select options={competitorsList} id="competitor" name="competitor" />
						</FormField>
						<FormField htmlFor="comments" name="comments" label="Tell Us Your Story">
							<TextArea
								id="comments"
								name="comments"
								placeholder="Enter your story"
							/>
						</FormField>
						<FormField
							htmlFor="fileinput"
							name="fileinput"
							label="Upload a file"
						>
							<FileInput
								messages={{
									dropPrompt: 'Drag and drop',
									browse: numFiles > 0 ? 'Replace file' : 'Select file',
								}}
								id="fileinput"
								onChange={(event, { files }) => () => {
									setNumFiles(files.length)
								}}
								name="fileinput"
							/>
						</FormField>
						<Box
							align={!['xsmall', 'small'].includes(size) ? 'start' : undefined}
							margin={{ top: 'medium', bottom: 'small' }}
							direction="row"
							justify="between"
						>
							<Button label="Send" primary type="submit" />
							<Button label="Close" secondary onClick={() => { onClickHandler() }} />
						</Box>
					</Form>
				</Box>
			</Box>
		</>
	);
}
const UserDetails = ({ size, ...rest }) => {
	const user = useSelector((state) => {
		return state.session.user;
	});
	const history = useHistory();
	const dispatch = useDispatch();

	const logout = () => {
		dispatch(userActions.logout());
		history.push("/index");
	};

	return (
		<>
			<Box width='medium'>
				{user && user.user && (
					<Box pad='medium' direction='row' gap='small' align="center">
						<Avatar background='blue!' flex={false} size='medium'>
							{user.family_name && user.given_name && (
								<Text size='large' color='text-strong'>
									{user.given_name[0] + user.family_name[0]}
								</Text>
							)}
						</Avatar>

						<Box pad={{ vertical: "small" }}>
							<Text color='text-strong' size='large' weight='bold'>
								{`${user.name}`}
							</Text>
							<Text size='small'>{user.user.email}</Text>
						</Box>
					</Box>
				)}
				<Box
					border={{
						side: "top",
						color: "border-weak",
					}}
					// align='end'
					// justify-content='right'
					gap="small"
					pad={{ horizontal: "xsmall", vertical: "small" }}
				>
					{/* {size === "small" && (
					<>
						<Button
							icon={<Calendar />}
							a11yTitle='Calendar'
							title='Calendar'
							onClick={() => {
								history.push("/calendar");
							}}
						/>
					</>
				)} */}
					{/* Notifocation option with stack to be commented out with next release */}
					{/* <Stack anchor="top-right">
						<Box direction="row" justify="between" gap="xlarge" width={"medium"} pad={"small"} onClick={() => { history.push('/competitor/notification-center') }}>
							<Box direction="row" gap="small" align="center">
								<Notification />
								<Text>Notification</Text>
							</Box>
							<FormNext />
						</Box>
						<Box background="orange" pad={{ horizontal: 'xxsmall' }} round>
							<Text size="xsmall">4</Text>
						</Box>
					</Stack> */}
					<Box direction="row" justify="between" gap="xlarge" width={"medium"} pad={"small"} onClick={logout}>
						<Box direction="row" gap="small" align="center">
							<Logout />
							<Text>Logout</Text>
						</Box>
						<FormNext />
					</Box>

					{/* <Button label='Log out' icon={<Logout />} reverse onClick={logout} /> */}
				</Box>
			</Box></>
	);
};
