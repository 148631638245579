import { ActionTypes } from "../constants/actionTypes";
import { analyticsService, surveyService } from "../../services";
import { alertError } from "./alertActions";
import { LogEvents } from "../constants/logEvents";

export const surveyActions = {
	submitSurvey,
};

function submitSurvey(user_id, rating, comments, demo_id, access, product_group_id, history) {
	return (dispatch) => {
		dispatch(request({}));
		surveyService
			.submitSurvey(user_id, rating, comments, demo_id, access)
			.then(() => {
				dispatch(success());
				analyticsService.logEvent(access, LogEvents.SURVEY_FINISHED, product_group_id, demo_id);
			})
			.catch((err) => {
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};

	function request(user) {
		return { type: ActionTypes.SURVEY_SUBMIT, user };
	}
	function success() {
		return { type: ActionTypes.SURVEY_SUCCESS };
	}
	function failure(error) {
		return { type: ActionTypes.SURVEY_FAILURE, error };
	}
}
