import React from "react";
import { Grid } from "grommet";
import { useSelector } from "react-redux";
import { CustomCard } from "./CustomCard";

export const CompetetiveDashboardGrid = ({ competitorsList, singleTypeList, history, size, ...rest }) => {
    const competitors = useSelector(() => {
        competitorsList.sort((a, b) => (a.order > b.order) ? 1 : -1);
        return competitorsList.map((group, index) => {
            const groupDet = {
                id: group.id,
                image: group.competitor_thumbnail,
                name: group.competitor_name,
                description: group.product_group_description,
                page_link: `/competitor/${group.id}`,
            };
            return (
                <CustomCard
                    key={groupDet.id}
                    title={groupDet.name}
                    image_url={groupDet.image}
                    onClickHandler={() => {
                        history.push(groupDet.page_link);
                    }}
                />
            );
        });
    });
    const singleClickTypes = useSelector(() => {
        if (singleTypeList) {
            singleTypeList.sort((a, b) => (a.order > b.order) ? 1 : -1)
            return singleTypeList.map((group, index) => {
                const groupDet = {
                    id: group.id,
                    image: group.image ? group.image : null,
                    name: group.card_type ? group.card_type : null,
                    description: group.product_group_description ? group.product_group_description : null,
                    page_link: `/tools/${group.id}&${group.card_type}`,
                };
                return (
                    <CustomCard
                        key={'singleClick' + groupDet.id}
                        title={groupDet.name}
                        image_url={groupDet.image}
                        onClickHandler={() => {
                            history.push(groupDet.page_link);
                        }}
                    />
                );
            });
        }
        else {
            return <></>
        }
    });

    return (
        <Grid
            columns={size === 'xlarge' || size === 'large' ? "20%" : '50%'} gap={"medium"}
            rows={[["auto", "full"]]}
            {...rest}
            pad='medium'
            fill
        >
            {[...competitors, ...singleClickTypes]}
        </Grid>
    );
};