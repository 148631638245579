import React, { forwardRef, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Box, ResponsiveContext, Text } from "grommet";
import { Hpe } from "grommet-icons";
import { MenuLayer } from ".";
import { useSelector } from "react-redux";

const brands = {
	hpe: {
		name: "HPE",
		logo: <Hpe color='brand' />,
	},
};

export const AppIdentity = forwardRef(({ brand, href, logoOnly, title, ...rest }, ref) => {
	const size = useContext(ResponsiveContext);
	const userSession = useSelector((state) => {
		return state.session;
	});

	return (
		<Box align='center' direction='row' gap={size !== "small" ? "medium" : "small"}>
			{/* menu is only available when user is logged in */}
			{userSession.athenticated && <MenuLayer />}
			<Button href={href} ref={ref} {...rest} plain>
				<Box
					direction='row'
					align='center'
					gap='medium'
					// pad maintains accessible hit target
					// non-responsive maintains same dimensions for mobile
					pad={{ vertical: "small" }}
					responsive={false}
				>
					{/* If user is logged in, show the application specific logo.
            This will be either HPE or Aruba. If user is logged out,
            show HPE logo */}
					{/* {brand && userSession ? brands[brand].logo : brands.hpe.logo} */}
					{!logoOnly && (
						<Box direction='row' gap='xsmall' wrap>
							{userSession ? (
								// user is signed in, display service name
								<Box direction='row' gap='xsmall'>
									<Text weight='bold' color='text-strong'>
										{brands[brand].name}
									</Text>
									<Text color='text-strong'>{title}</Text>
								</Box>
							) : (
								// user is not signed in
								<Text color='text-strong' weight='bold'>
									HPE Hybrid Cloud Competitive Intelligence Application
								</Text>
							)}
						</Box>
					)}
				</Box>
			</Button>
		</Box>
	);
});

AppIdentity.propTypes = {
	brand: PropTypes.string.isRequired,
	logoOnly: PropTypes.bool,
	href: PropTypes.string,
	title: PropTypes.string,
};
