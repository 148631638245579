import { ActionTypes } from "../constants/actionTypes";

const initialState = {
    competitors: { data: null, requestInProgress: false, error: null },
    singleclicktypes: { data: null, requestInProgress: false, error: null }
};

export function competitiveDash(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.COMPETITOR_REQUEST:
            return { ...state, competitors: { requestInProgress: true } };
        case ActionTypes.COMPETITOR_SUCCESS:
            return { ...state, competitors: { data: action.competitors, requestInProgress: false } };
        case ActionTypes.COMPETITOR_FAILURE:
            return { ...state, competitors: { requestInProgress: false, error: action.error } };
        case ActionTypes.SINGLE_CLICK_TYPE_REQUEST:
            return { ...state, singleclicktypes: { requestInProgress: true } };
        case ActionTypes.SINGLE_CLICK_TYPE_SUCCESS:
            return { ...state, singleclicktypes: { data: action.singleclicktypes, requestInProgress: false } };
        case ActionTypes.SINGLE_CLICK_TYPE_FAILURE:
            return { ...state, singleclicktypes: { requestInProgress: false, error: action.error } };
        default:
            return state;
    }
}
