import React, { useEffect, useState, useContext } from "react";
import { Box, Text, Button, Spinner } from "grommet";
import { Accordion, AccordionPanel, ResponsiveContext } from "grommet";
import { ScreenCard } from "../components/clickthrough";
import { useParams } from "react-router-dom";
import { demoService } from "../services";

export const EmbedDemo = () => {
    const size = useContext(ResponsiveContext);
    const { id } = useParams();
    const [sidebar, setSidebar] = useState(true);
    const [isLastScreen, setisLastScreen] = useState(false);
    const [demo, setDemo] = useState({});
    const [slides, setSlides] = useState([]);
    const [currentSlide, setcurrentSlide] = useState({});
    const [currentPosition, setCurrentPosition] = useState(0); // Initial slide index value
    const [loading, setLoading] = useState(true);
    const pad = 'small';

    useEffect(() => {
        if (size === "small") {
            setSidebar(false);
        } else {
            setSidebar(true);
        }
    }, [size])

    useEffect(() => {
        demoService
            .getDemoWithoutToken(id)
            .then((demo) => {
                if (demo && demo.screens && demo.screens.length) {
                    setDemo(demo);
                    demo.screens.sort((a, b) => (a.order > b.order) ? 1 : -1);
                    setSlides(demo.screens);
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [id]);

    useEffect(() => {
        setcurrentSlide(slides[currentPosition]);
    }, [slides, currentPosition]);

    const nextClick = () => {
        if (currentPosition === slides.length - 2) {
            setisLastScreen(true);
        }
        setCurrentPosition(currentPosition + 1);
    };

    const moveToScreen = (index, event) => {
        setisLastScreen(false);
        if (currentPosition === index - 2) {
            setisLastScreen(true);
        }
        setCurrentPosition(index);
    };

    return <>{<Box gap='medium' height="100%" >
        <Box
            style={{
                display: loading ? "block" : "none",
                position: "absolute",
                padding: 0,
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                textAlign: "center !important",
                verticalAlign: "middle",
            }}
            size="cover"
        >
            <Spinner style={{
                marginTop: "20%",
                marginLeft: "47%",
            }} />
        </Box>
        {slides.length > 0 && (
            <Box
                direction={size !== "small" ? "row" : "column"} height="100%" >
                {!sidebar &&
                    <Box
                        direction="row-responsive"
                        align="center"
                        justify="between"
                        pad={{ horizontal: 'medium', vertical: 'small' }}
                        background="#e5e5e5"
                        color="#000"
                    >
                        <Button onClick={() => setSidebar(!sidebar)} style={{ color: "#000" }}>
                            <Text size="small" weight="bold">{demo.title}</Text>
                        </Button>
                    </Box>
                }

                {sidebar && (
                    <Box
                        border={{ color: "brand", size: "medium" }} round='small' width="fill"
                        animation={[
                            { type: 'fadeIn', duration: 300 },
                            { type: 'slideRight', size: 'xlarge', duration: 150 },
                        ]}
                        style={{ fontSize: "15px", overflow: "auto" }}
                    >
                        <Box
                            fill='horizontal'
                            style={{
                                fontSize: "15px", overflow: "auto", height: "100%", borderTop: 0
                            }}>
                            <Accordion expand="true" activeIndex={currentPosition}
                                style={{
                                    hover: { heading: { "color": "#fff", background: "#000" } }
                                }}
                                width='fill'
                                pad='medium'
                            >
                                {slides.map((slide, index) => (
                                    <AccordionPanel onClick={moveToScreen.bind(null, index)} key={slide.id} width="100%"
                                        label={<Box fill>
                                            <Text style={{ padding: "10px", fontSize: "15px", fontWeight: "bold" }}>{slide.title}</Text></Box>} >
                                        <Box pad={pad} width="100%" minWidth="100%">{slide.description}</Box>
                                    </AccordionPanel>
                                ))}
                            </Accordion>
                        </Box>
                    </Box>
                )}
                <Box height="100%" width={size !== "small" ? "70%" : "100%"} >
                    {currentSlide && currentSlide.id &&
                        <ScreenCard
                            slide={currentSlide}
                            boxOnClick={nextClick}
                            isLastScreen={isLastScreen}
                            setCurrentPosition={setCurrentPosition}
                            setisLastScreen={setisLastScreen}
                            key={currentSlide.id} />
                    }
                </Box>
            </Box >
        )}
    </Box >
    }</>;
};

export default EmbedDemo;
