import React, { useContext, useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveContext, Layer, Spinner, Page, PageContent, PageHeader } from 'grommet'
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReverseAnchor } from '../components/competetive/ReverseAnchor';
import { competetiveActions } from "../redux/actions/competetiveActions";
import { CompetetiveProductsGrid } from '../components/competetive/CompetetiveProductsGrid';
import { competetiveService } from '../services';
import { CompetitiveSinlgeClickGrid } from '../components/competetive/CompetitiveSingleClickGrid';


function CompetitorProductListPage({ props }) {
    const [isSinlgeClick, setIsSinlgeClick] = useState(false)
    const size = useContext(ResponsiveContext);
    const dispatch = useDispatch();
    const { id, name } = useParams();

    const session = useSelector((state) => {
        return state.session;
    });
    const [selectedCompetitor, setSelectedCompetitor] = useState()
    useEffect(() => {
        if (session && session.user && session.user.access && window.location.href.indexOf("tools") > -1) {
            setIsSinlgeClick(true)
            dispatch(competetiveActions.getSingleClickResource(id, session.user.access));
        }
        else if (session && session.user && session.user.access) {
            dispatch(competetiveActions.getCompetitorProducts(id, session.user.access));
            competetiveService.getCompetitorById(session.user.access, id).then((response) => setSelectedCompetitor(response.competitor_name))
        }
    }, [session, id, dispatch]);


    const competitorProductList = useSelector((state) => {
        return state.competitiveProducts.competitorProducts
    });
    const singleClickList = useSelector((state) => {
        return state.competitiveProducts.singelClickResource
    });
    const history = useHistory();
    return (
        <>
            <Page>
                <PageContent gap='medium'>
                    {isSinlgeClick ? <>
                        {singleClickList.requestInProgress && (<Layer position='center' background='none' style={{ boxShadow: "none" }}>
                            <Spinner />
                        </Layer>)}
                        {singleClickList.data &&
                            <>
                                <PageHeader parent={<ReverseAnchor label={"Competitors"} onClick={() => { window.history.go(-1) }} />} title={isSinlgeClick ? name : selectedCompetitor}
                                // actions={<Box>
                                //     {!isFollowing ? <Button secondary label="Subscibe" onClick={() => {
                                //         setIsFollowing(true);
                                //         setVisible(true)
                                //     }} /> : <Button label="Unsubscribe" onClick={() => { setIsFollowing(false); }} />}
                                // </Box>} 
                                />
                                {/* <Box width={"medium"}>
                        <StyledTextInput icon={<Search id="search-icon" color="placeholder" />} placeholder="Search" reverse value={searchValue} onChange={event => setSearchValue(event.target.value)} type="search" />
                    </Box> */}
                                {
                                    <CompetitiveSinlgeClickGrid productsList={singleClickList.data} singelClickId={id} history={history} size={size} />
                                }
                            </>
                        }
                    </> : <>   {
                        competitorProductList.requestInProgress && (<Layer position='center' background='none' style={{ boxShadow: "none" }}>
                            <Spinner />
                        </Layer>)}
                        {competitorProductList.data &&
                            <>
                                <PageHeader parent={<ReverseAnchor label={"Competitors"} onClick={() => { window.history.go(-1) }} />} title={isSinlgeClick ? name : selectedCompetitor}
                                />
                                {
                                    <CompetetiveProductsGrid productsList={competitorProductList.data} competitorId={id} history={history} size={size} />
                                }
                            </>
                        }</>}
                </PageContent>
            </Page>
        </>
    )
}

export default CompetitorProductListPage