import config from "../config";
import handleResponse from "./helper";

export const demoService = {
	getProductDemoList,
	getDemo,
	getDemoWithoutToken,
};

function getProductDemoList(id, token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/demo/product-groups/${id}`, requestOptions).then(handleResponse);
}

function getDemo(id, token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/demo/demos/${id}`, requestOptions).then(handleResponse);
}

function getDemoWithoutToken(id) {
	return fetch(`${config.apiUrl}/demo/public_demos/${id}`).then(handleResponse);
}
