import React, { useState } from "react";
import { Box, Page, PageContent, PageHeader, Button, Tabs, Tab, List, Text, Layer, Form, FormField, CheckBoxGroup } from "grommet";
import { Announce, Configure, Notification, } from "grommet-icons";
import { ReverseAnchor } from "../components/competetive/ReverseAnchor";

function NotificationCenter() {

    // eslint-disable-next-line no-unused-vars

    const [showModal, setShowModal] = useState(false);
    const [index, setIndex] = useState();
    const onActive = nextIndex => setIndex(nextIndex);
    const data = [
        {
            title: 'New Document added',
            description: 'Battle Card for Dell Unity',
            date: '24/08/2023',
        },
        {
            title: 'Competetive Win',
            description: 'We won agains pure storage.',
            date: '20/08/2023',
        },
        {
            title: 'Useful links have been added',
            description: 'use full links are added to netapp',
            date: '02/08/2023',
        },
        {
            title: 'New Document added',
            description: 'Battle Card for Dell powerstore',
            date: '01/08/2023',
        },
    ];

    return (
        <>
            <Page>
                <PageContent gap={"medium"} >
                    <PageHeader title={"Notification Center"} subtitle={"See all your notification here"} parent={<ReverseAnchor label={"Home"} href={"/"} />} actions={<Button secondary label="Settings" icon={<Configure />} onClick={() => { setShowModal(true) }} />} />
                    <Tabs activeIndex={index} onActive={onActive} justify="start">
                        <Tab title="Announcements" icon={<Announce />}>
                            <Box width={{ max: 'xxlarge' }} margin="auto" fill>
                                <List
                                    background="background-front"
                                    data={data}
                                    action={(item, index) => <Text key={index}>{item.date}</Text>}
                                    onClickItem={e => {
                                        window.open("https://v2.grommet.io/tabs?theme=hpe#props")
                                    }}
                                >
                                    {(datum, index) => (
                                        <Box key={index}>
                                            <Text size="small" weight={"bolder"}>{datum.title}</Text>
                                            <Text size="small">{datum.description}</Text>
                                        </Box>
                                    )}
                                </List>
                            </Box>
                        </Tab>
                        <Tab title="Subcribed Notification" icon={<Notification />}>
                            <Box>
                                <Text>
                                    you have not followed any competitors!
                                </Text>
                            </Box>
                        </Tab>
                    </Tabs>
                </PageContent>
                {showModal && <Layer onClickOutside={() => { setShowModal(false) }}>
                    <Box pad={"medium"} gap="medium">
                        <Box>
                            <Text size="large">Settings</Text>
                            <Text>Change setting based on your needs</Text>
                        </Box>
                        <Form >
                            <Box gap="medium">
                                <FormField label="Notification Settings">
                                    <CheckBoxGroup options={['Receive Notifivation via Mail', 'Receive Notification in App']} />
                                </FormField>
                                <Box direction="row" justify="between">
                                    <Button secondary label="Cancel" onClick={() => { setShowModal(false) }} />
                                    <Button type="submit" primary label="Save Settings" onClick={() => { setShowModal(false) }} />
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                </Layer>}
            </Page>
        </>
    );
}

export default NotificationCenter