import config from "../config";
import handleResponse from "./helper";

export const productGroupService = {
	getProductGroupList,
	getProductGroup,
	getContentTypes,
};

function getProductGroupList(token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/products/product-groups/`, requestOptions).then(handleResponse);
}

function getProductGroup(id, token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/products/product-groups/${id}`, requestOptions).then(handleResponse);
}

function getContentTypes(token) {
	const requestOptions = {
		method: "GET",
		headers: { Authorization: "Bearer " + token },
	};
	return fetch(`${config.apiUrl}/demo/content-types/`, requestOptions).then(handleResponse);
}
