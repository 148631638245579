import React, { useContext } from "react";
import { Box, Heading, Header, Text, Image, ResponsiveContext } from "grommet";

export const ProductGroupHead = (props) => {
	const size = useContext(ResponsiveContext);

	return (
		<Header
			height='auto'
			pad={{ vertical: "small", horizontal: "medium" }}
			justify='between'
			gap='large'
			direction={size !== "small" ? "row" : "column"}
		>
			<Box
				direction='row'
				height={size !== "small" ? "small" : "xsmall"}
				width={size !== "small" ? "50%" : "auto"}
			>
				<Image fit='contain' src={props.productGroup.product_group_thumbnail} />
			</Box>
			<Box height={size !== "small" ? "100%" : "auto"} width={size !== "small" ? "50%" : "auto"} pad='medium'>
				<Heading margin='none' level={4} pad='small'>
					{props.productGroup.product_group_name}
				</Heading>
				{size !== "small" && (
					<Text size='small' pad='small'>
						{props.productGroup.product_group_description}
					</Text>
				)}
			</Box>
		</Header>
	);
};
