import { useContext, useEffect, useMemo, useState, cloneElement } from 'react';
import { Box, Grid, Meter, ResponsiveContext, ThemeContext, NameValuePair, Text, List, Card, CardBody, CardFooter, CardHeader, Heading, Menu, Button } from 'grommet';
import { parseMetricToNum } from 'grommet/utils';
import { MoreVertical } from 'grommet-icons';

const formatCurrency = (value, locale = 'en-US', currency = 'USD') =>
    Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
    }).format(value);
const mockAccountData = require('../../data/accounts.json');

const account = mockAccountData.accounts.find(
    a => a.id === '407f1f7g6ty86cd56te3903y',
);

// Date examples should use to represent "today's date". Since mock data
// is currently static, chose a fixed date which aligns with the data.
const DEMO_DATE = new Date('2022-02-22');
const current = DEMO_DATE;
const endDate = current;
const beginDate = new Date(new Date().setDate(current.getDate() - 365));

const defaultWindow = { begin: beginDate, end: endDate };

const REPORT_WINDOW_MAP = {
    'Last 30 Days': 30,
    'Last Year': 365,
    Lifetime:
        365 *
        (current.getFullYear() - new Date(account.createdDate).getFullYear() + 1),
};
const MOCK_DATA = require('../../data/consumtion.json');
const DashboardCardHeader = ({ title, level, subtitle, menuItems }) => (
    <Box direction="row" align="start" justify="between" fill>
        <Box>
            <Heading margin="none" level={level}>
                {title}
            </Heading>
            {subtitle && <Text>{subtitle}</Text>}
        </Box>
        {menuItems && (
            <Menu
                icon={<MoreVertical />}
                items={menuItems}
                dropAlign={{ top: 'bottom', right: 'right' }}
            />
        )}
    </Box>
);
export const Legend = ({ values, ...rest }) => {
    const theme = useContext(ThemeContext);
    const size = theme.global.edgeSize.small;

    return (
        <List
            data={values}
            defaultItemProps={{ pad: { vertical: 'xxsmall' } }}
            {...rest}
        >
            {datum => (
                <Box direction="row" gap="small" justify="between">
                    <Box direction="row" align="center" gap="xsmall">
                        <Box background={datum.color} height={size} width={size} round />
                        <Text>{datum.label}</Text>
                    </Box>
                    <Text>{datum.displayValue}</Text>
                </Box>
            )}
        </List>
    );
};
export const ChartCard = ({
    title,
    subtitle,
    children,
    footer,
    onClick,
    ...rest
}) => {
    const size = useContext(ResponsiveContext);
    const theme = useContext(ThemeContext);
    const { body, header, footer: footerTheme } = theme.card;

    return (
        <Card onClick={onClick} {...rest}>
            <CardHeader
                pad={
                    ['xsmall', 'small'].includes(size)
                        ? { horizontal: 'large', top: 'large', bottom: header.pad }
                        : { horizontal: header.pad, top: header.pad, bottom: 'small' }
                }
            >
                <DashboardCardHeader title={title} level={2} subtitle={subtitle} />
            </CardHeader>
            <CardBody
                pad={
                    ['xsmall', 'small'].includes(size)
                        ? { horizontal: 'large', top: body.pad, bottom: 'large' }
                        : { horizontal: body.pad, top: 'small', bottom: body.pad }
                }
            >
                {children}
            </CardBody>
            {footer && (
                <CardFooter
                    pad={
                        ['xsmall', 'small'].includes(size)
                            ? { horizontal: 'large', top: body.pad, bottom: 'large' }
                            : footerTheme.pad
                    }
                    border={{ side: 'top', color: 'border-weak' }}
                >
                    {footer}
                </CardFooter>
            )}
        </Card>
    );
};
export const Measure = ({ name, value: valueProp, onClick, ...rest }) => {
    const { icon: iconProp, label } = name;
    const icon = iconProp ? cloneElement(iconProp, { size: 'small' }) : null;

    const value = valueProp?.value || valueProp;
    const valueSize = valueProp?.size || 'xxlarge';

    let contents = (
        <NameValuePair
            name={
                <Box
                    direction="row"
                    align="center"
                    gap="small"
                    // margin is needed to keep consistent with the spacing
                    // delivered by the theme when name is typeof 'string'
                    // https://github.com/grommet/grommet/blob/db5be926eb7c2f791534f02dd55b0f9997e959db/src/js/themes/base.js#L1072
                    margin={{ bottom: 'xxsmall' }}
                >
                    {icon}
                    <Text size={name.label?.size || 'small'}>
                        {label?.label || label}
                    </Text>
                </Box>
            }
        >
            <Text size={valueSize}>{value}</Text>
        </NameValuePair>
    );

    if (onClick) {
        contents = <Button onClick={onClick}>{contents}</Button>;
    }

    return <Box {...rest}>{contents}</Box>;
};
export const PieChart = ({ period, notification }) => {
    const [values, setValues] = useState(null);
    const [totalCost, setTotalCost] = useState(null);
    const [reportWindow, setReportWindow] = useState(defaultWindow);
    const consumptionData = MOCK_DATA.consumption;
    const size = useContext(ResponsiveContext);
    const theme = useContext(ThemeContext);
    const chartColors = useMemo(
        () =>
            Object.entries(theme.global.colors)
                .filter(([key]) => key.includes('graph'))
                .map(([, value]) => value),
        [theme.global.colors],
    );

    // Set reporting window
    useEffect(() => {
        setReportWindow(prevWindow => ({
            ...prevWindow,
            begin: new Date(
                new Date(DEMO_DATE).setDate(
                    prevWindow.end.getDate() - REPORT_WINDOW_MAP[period],
                ),
            ),
        }));
    }, [period]);

    // Filter and assemble data set
    useEffect(() => {
        if (consumptionData) {
            const nextValues = [];
            consumptionData.forEach(datum => {

                const dataPointDate = new Date(datum.endDate);
                if (
                    dataPointDate > reportWindow.begin &&
                    dataPointDate < reportWindow.end
                ) {
                    const key = datum.service;
                    const value = parseFloat(datum.cost.replace(/[$,]/gm, ''));
                    const index = nextValues.findIndex(el => el.key === key);

                    if (index >= 0) {
                        const nextValue = nextValues[index].value + value;
                        nextValues[index].value = nextValue;
                        nextValues[index].displayValue = formatCurrency(nextValue);
                    } else {
                        nextValues.push({
                            key,
                            label: `${datum.service[0].toLocaleUpperCase()}${datum.service
                                .slice(1)
                                .toLocaleLowerCase()}`,
                            value,
                            displayValue: formatCurrency(value),
                            color: undefined,
                        });
                    }
                }
            });
            nextValues
                .sort((a, b) => b.value - a.value)
                .forEach((value, index) => {
                    // eslint-disable-next-line no-param-reassign
                    value.color = chartColors[index % chartColors.length];
                });
            setValues(nextValues);
        }
    }, [chartColors, consumptionData, reportWindow.begin, reportWindow.end]);

    // Calculate total cost
    useEffect(() => {
        let nextTotalCost = 0;
        if (values) {
            Object.keys(values).forEach(key => {
                nextTotalCost += values[key].value;
            });
            setTotalCost(nextTotalCost);
        }
    }, [values]);

    const grid = {
        columns: ['auto', 'auto'],
        rows: ['auto', 'auto'],
        areas: {
            xsmall: [
                ['chart', 'measure'],
                ['chart', 'legend'],
            ],
            small: [
                ['chart', 'measure'],
                ['chart', 'legend'],
            ],
            medium: [
                ['chart', 'measure'],
                ['chart', 'legend'],
            ],
            large: [
                ['chart', 'measure'],
                ['legend', 'legend'],
            ],
            xlarge: [
                ['chart', 'measure'],
                ['chart', 'legend'],
            ],
        },
        gap: {
            xsmall: 'medium',
            small: 'medium',
            medium: 'small',
            large: 'medium',
            xlarge: 'medium',
        },
    };

    return (
        <ChartCard title="Documents by Competitor" subtitle={period}>
            <Box gap="medium">
                {notification}
                {values && (
                    <Grid
                        columns={grid.columns}
                        rows={grid.rows}
                        areas={grid.areas[size]}
                        gap={grid.gap[size]}
                    >
                        <Box
                            gridArea="chart"
                            alignSelf="start"
                            height={{
                                max: `${parseMetricToNum(theme.global.size.small) +
                                    parseMetricToNum(theme.global.size.xsmall)
                                    }px`,
                            }}
                        >
                            <Meter
                                values={values.map(value => ({
                                    label: value.label,
                                    value: value.value,
                                }))}
                                type="pie"
                                size="full"
                            />
                        </Box>
                        <Measure
                            gridArea="measure"
                            alignSelf="center"
                            name={{ label: { label: 'Total Cost', size: 'medium' } }}
                            value={{
                                value: formatCurrency(totalCost),
                                size: 'xlarge',
                            }}
                        />
                        <Legend gridArea="legend" values={values} />
                    </Grid>
                )}
            </Box>
        </ChartCard>
    );
}