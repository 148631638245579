import { ActionTypes } from "../constants/actionTypes";
import { analyticsService, feedbackService } from "../../services";
import { alertError } from "./alertActions";
import { LogEvents } from "../constants/logEvents";

export const feedbackActions = {
	submitFeedback,
};

function submitFeedback(user_id, rating, found_information, experience, comments, access, history) {
	return (dispatch) => {
		dispatch(request({}));
		feedbackService
			.submitFeedback(user_id, rating, found_information, experience, comments, access)
			.then(() => {
				dispatch(success());
				analyticsService.logEvent(access, LogEvents.FEEDBACK_FINISHED);
			})
			.catch((err) => {
				dispatch(failure(err));
				dispatch(alertError(err));
			});
	};

	function request(user) {
		return { type: ActionTypes.FEEDBACK_SUBMIT, user };
	}
	function success() {
		return { type: ActionTypes.FEEDBACK_SUCCESS };
	}
	function failure(error) {
		return { type: ActionTypes.FEEDBACK_FAILURE, error };
	}
}
