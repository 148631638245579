import React, { useEffect, useState } from 'react'
import { Page, PageContent, PageHeader, Grid, Layer, Spinner, Text } from 'grommet'
import { ReverseAnchor } from '../components/competetive/ReverseAnchor';
import { CustomCard } from '../components/competetive/CustomCard';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { competetiveService } from '../services';

function CompetitorBattleCards() {
    const { product_id, res_id } = useParams()
    const dispatch = useDispatch();
    const [pageTitle, setPageTitle] = useState('')
    const [anchorTitle, setAnchorTitle] = useState('')
    const [resourceList, setResourceList] = useState([]);
    const session = useSelector((state) => {
        return state.session;
    });
    useEffect(() => {
        if (session && session.user && session.user.access) {
            competetiveService.getCompetitorProductById(session.user.access, product_id).then((response) => {
                setAnchorTitle(response.competitor_product_group)
                setPageTitle(response.competitor_product_name + ' Resources')
            })
        }
    }, [session, dispatch, res_id, product_id]);

    useEffect(() => {
        competetiveService.getResources(product_id, 1).then((response) => {
            setResourceList(response);
        })
    }, [res_id, product_id,])
    return <>
        <Page>
            <PageContent>
                {resourceList.requestInProgress &&
                    <Layer position='center' background='none' style={{ boxShadow: "none" }}>
                        <Spinner />
                    </Layer>
                }

                {resourceList &&
                    <>
                        <PageHeader parent={<ReverseAnchor label={anchorTitle} onClick={() => { window.history.go(-1) }} />} title={pageTitle}
                        />
                        {resourceList.length > 0 ? <Grid columns={"25%"} gap={"medium"}>
                            {
                                resourceList.map((resource, index) => (
                                    <CustomCard key={`${index}_${resource.resource_title}`} title={resource.resource_title} description={resource.resource_content_type} border={true} color={resource.resource_content_type_color} onClickHandler={() => { window.open(resource.link) }} />
                                ))
                            }
                        </Grid> : resourceList.data === null ? <Text> No Resources available for the selected product</Text> : <Spinner alignSelf='center' />}
                    </>
                }
            </PageContent>
        </Page>
    </>
}

export default CompetitorBattleCards