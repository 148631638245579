import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveContext, Page, PageContent, PageHeader, Layer, Spinner, Text } from "grommet";
// import header_image from "../../../static/img/hpe_storage.jpg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { competetiveActions } from "../redux/actions/competetiveActions";
import { CompetetiveDashboardGrid } from "../components/competetive/CompetetiveDashboardGrid";


function CompetitorDashboard() {
    const size = useContext(ResponsiveContext);
    const history = useHistory();
    const dispatch = useDispatch();
    // const StyledTextInput = styled(TextInput).attrs(() => ({
    //     'aria-labelledby': 'search-icon',
    // }))``;
    // const [searchValue, setSearchValue] = useState("");
    // const card_desc = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"

    const session = useSelector((state) => {
        return state.session;
    });

    // const user = useSelector((state) => {
    //     return state.session.user;
    // });

    useEffect(() => {
        if (session && session.user && session.user.access) {
            dispatch(competetiveActions.getCompetitors(session.user.access));
            dispatch(competetiveActions.getSingleClickTypes(session.user.access));
        }
    }, [session, dispatch]);

    const competitorList = useSelector((state) => {
        return state.competitiveDash.competitors
    });
    const singleClickList = useSelector((state) => {
        return state.competitiveDash.singleclicktypes
    });
    return (
        <>
            <Page>
                <PageContent gap={"medium"} >
                    <PageHeader title={"HPE Hybrid Cloud Competitive Intelligence"}
                    // subtitle={"An Application to fulfill your competitive needs"} 
                    />
                    {/* <Box width={"medium"}>
                        <StyledTextInput icon={<Search id="search-icon" color="placeholder" />} placeholder="Search" reverse value={searchValue} onChange={event => setSearchValue(event.target.value)} type="search" />
                    </Box> */}
                    {(competitorList.requestInProgress || singleClickList.requestInProgress) && (
                        <Layer position='center' background='none' style={{ boxShadow: "none" }}>
                            <Spinner />
                        </Layer>
                    )}
                    {competitorList.data && competitorList.data.length > 0 && singleClickList.data ? (
                        <CompetetiveDashboardGrid competitorsList={competitorList.data} singleTypeList={singleClickList.data} history={history} size={size} />
                    ) : <Text>
                        No Competitors Found
                    </Text>}
                    {/* {singleClickList.data && singleClickList.data.length > 0 ? (
                        <CompetetiveDashboardGrid competitorsList={singleClickList.data} history={history} size={size} />
                    ) : <></>} */}
                </PageContent>
            </Page>
        </>
    );
}

export default CompetitorDashboard